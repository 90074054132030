
const CURRENT_ADMISISON_BATCH = '2023 Batch'
const admission_categories = [

    { AdmissionCategory: 1, AdmissionCategoryName: "General" },
    { AdmissionCategory: 2, AdmissionCategoryName: "Lateral Entry" },
    { AdmissionCategory: 3, AdmissionCategoryName: "Scholarship" },
    { AdmissionCategory: 4, AdmissionCategoryName: "CPD" },
    { AdmissionCategory: 5, AdmissionCategoryName: "Quota" },
    { AdmissionCategory: 6, AdmissionCategoryName: "Parttime" },
    { AdmissionCategory: 7, AdmissionCategoryName: "Transfer" },
    { AdmissionCategory: 8, AdmissionCategoryName: "ODL" },
]

const admission_type = [
    { AdmissionType: 1, AdmissionTypeName: "Management" },
    { AdmissionType: 2, AdmissionTypeName: "Entrance" },
    { AdmissionType: 3, AdmissionTypeName: "Sports" },
    { AdmissionType: 4, AdmissionTypeName: "Scholarship"}
]

const application_status = [
    { value: 0, text: "Open / Partially Paid" },
    { value: 1, text: "Pending" },
    { value: 2, text: "Completed / Full Paid" },
    { value: 3, text: "Refund / Reject" }
]
const phD_status = [
    { value: 2, text: "Completed" },
    { value: 1, text: "Registered" },
    { value: 0, text: "Not Registered" }
]

const application_status_without_Reject = [
    { value: 0, text: "Open / Partially Paid" },
    { value: 1, text: "Pending" },
    { value: 2, text: "Completed / Full Paid" },
]

const logType = [
    { Type: 0, LogType: "Pickup" },
    { Type: 1, LogType: "Drop" },

]
const menuItemType = [
    { Type: 0, MenuItemType: "Veg" },
    { Type: 1, MenuItemType: "NonVeg" },
    { Type: 2, MenuItemType: "Common" },
]

const messGenderAllowed = [
    { value: 0, MessGenderAllowed: "Male" },
    { value: 1, MessGenderAllowed: "Female" },
    { value: 2, MessGenderAllowed: "Both" },

]

const community = [

    { CommunityId: 1, CommunityName: "General/OC" },
    { CommunityId: 2, CommunityName: "OBC/BC" },
    { CommunityId: 3, CommunityName: "MBC" },
    { CommunityId: 4, CommunityName: "SC" },
    { CommunityId: 5, CommunityName: "ST" },
]
const nationality = [
    { NationalityId: 1, NationalityName: "Indian" },
    { NationalityId: 2, NationalityName: "NRI" },
    { NationalityId: 3, NationalityName: "Persons of Indian Origin" },
    { NationalityId: 4, NationalityName: "Foreign National" },
]
const religion = [

    { ReligionId: 1, ReligionName: "Hindu" },
    { ReligionId: 2, ReligionName: "Christian" },
    { ReligionId: 3, ReligionName: "Muslim" },
    { ReligionId: 4, ReligionName: "Others" },
]
const gender = [

    { Gender: 1, GenderName: "Male" },
    { Gender: 2, GenderName: "Female" },
    { Gender: 3, GenderName: "Others" },
]

const department = [
    { Department: 1, DepartmentName: "CSE" },
    { Department: 2, DepartmentName: "MECH" },
    { Department: 3, DepartmentName: "EEE" },
    { Department: 4, DepartmentName: "ECE" },
    { Department: 5, DepartmentName: "IT" },
    { Department: 6, DepartmentName: "CIVIL" },
]

const mother_tongue = [
    { MonthTongueId: '1', MonthTongueName: "ASSAMESE" },
    { MonthTongueId: '2', MonthTongueName: "BENGALI" },
    { MonthTongueId: '3', MonthTongueName: "BODO" },
    { MonthTongueId: '4', MonthTongueName: "DOGRI" },
    { MonthTongueId: '5', MonthTongueName: "GUJARATI" },
    { MonthTongueId: '6', MonthTongueName: "HINDI" },
    { MonthTongueId: '7', MonthTongueName: "KANNADA" },
    { MonthTongueId: '8', MonthTongueName: "KASHMIRI" },
    { MonthTongueId: '9', MonthTongueName: "KONKINI" },
    { MonthTongueId: '10', MonthTongueName: "MAITHILI" },
    { MonthTongueId: '11', MonthTongueName: "MALAYALAM" },
    { MonthTongueId: '12', MonthTongueName: "MANIPURI" },
    { MonthTongueId: '13', MonthTongueName: "MARATHI" },
    { MonthTongueId: '14', MonthTongueName: "NEPALI" },
    { MonthTongueId: '15', MonthTongueName: "ORIYA" },
    { MonthTongueId: '16', MonthTongueName: "PUNJABI" },
    { MonthTongueId: '17', MonthTongueName: "SANSKRIT" },
    { MonthTongueId: '18', MonthTongueName: "TAMIL" },
    { MonthTongueId: '19', MonthTongueName: "TELUGU" },
    { MonthTongueId: '20', MonthTongueName: "URDU" },
    { MonthTongueId: '21', MonthTongueName: "ENGLISH" },
    { MonthTongueId: '22', MonthTongueName: "OTHERS" },
]
const blood_group = [
    { BloodGroupId: 1, BloodGroupName: "A1 +ve" },
    { BloodGroupId: 2, BloodGroupName: "A2 +ve" },
    { BloodGroupId: 3, BloodGroupName: "B +ve" },
    { BloodGroupId: 4, BloodGroupName: "O -ve" },
    { BloodGroupId: 5, BloodGroupName: "A1B -ve" },
    { BloodGroupId: 6, BloodGroupName: "A2B -ve" },
    { BloodGroupId: 7, BloodGroupName: "A1 -ve" },
    { BloodGroupId: 8, BloodGroupName: "A2 -ve" },
    { BloodGroupId: 9, BloodGroupName: "O +ve" },
    { BloodGroupId: 10, BloodGroupName: "B -ve" },
    { BloodGroupId: 11, BloodGroupName: "A1B +ve'" },
    { BloodGroupId: 12, BloodGroupName: "A2B +ve'" },
]
//2021 admisison completed so disabled it. This section will be enabled when we strat the 2022 admisison-->
const payment_type = [
    { PaymentTypeId: 1, PaymentTypeName: "Direct" },
    { PaymentTypeId: 2, PaymentTypeName: "DD" },
    { PaymentTypeId: 3, PaymentTypeName: "Cheque" },
    { PaymentTypeId: 4, PaymentTypeName: "Online" },
    { PaymentTypeId: 5, PaymentTypeName: "Card" },
]

const relieve_status = [
    { value: 1, text: "Yes" },
    { value: 0, text: "No" },
]

const staff_type = [
    { value: 1, text: "Teaching Staff" },
    { value: 2, text: "Non Teaching Staff" },
]
const experience_type = [
    { value: 1, text: "Industry" },
    { value: 2, text: "Academic" },
    { value: 3, text: "Research" },
]
const months = [
    { value: 1, text: "January" },
    { value: 2, text: "February" },
    { value: 3, text: "March" },
    { value: 4, text: "April" },
    { value: 5, text: "May" },
    { value: 6, text: "June" },
    { value: 7, text: "July" },
    { value: 8, text: "August" },
    { value: 9, text: "September" },
    { value: 10, text: "October" },
    { value: 11, text: "November" },
    { value: 12, text: "December" },
]
// below array has created for using under employee journal section. to transform month number into month text

const monthName = [
    { Month: 1, text: "January" },
    { Month: 2, text: "February" },
    { Month: 3, text: "March" },
    { Month: 4, text: "April" },
    { Month: 5, text: "May" },
    { Month: 6, text: "June" },
    { Month: 7, text: "July" },
    { Month: 8, text: "August" },
    { Month: 9, text: "September" },
    { Month: 10, text: "October" },
    { Month: 11, text: "November" },
    { Month: 12, text: "December" },
]


const fee_paid_category = [
    { value: 0, text: "Paid" },
    { value: 1, text: "Refund" },
]

const leave_status = [
    { value: 0, text: "Open", cssClass: "badge badge-secondary" },
    { value: 1, text: "Approved", cssClass: "badge badge-success" },
    { value: 2, text: "Rejected", cssClass: "badge badge-danger" },
    { value: 3, text: "Cancelled", cssClass: "badge badge-warning" },

]
const Hostel_Students_Type = [
    { value: 0, text: "GENERAL", cssClass: "badge badge-success" },
    { value: 1, text: "SCHOLARSHIP", cssClass: "badge badge-primary" },
    { value: 2, text: "CPD", cssClass: "badge badge-info" }
]
const lor_satus = [
    { value: 1, text: 'Approved' },
    { value: 0, text: 'Approval Pending' },
    { value: 2, text: 'Rejected' }
]

const staff_status = [
    { value: 1, text: "Active", cssClass: "badge badge-success" },
    { value: 0, text: "In Active", cssClass: "badge badge-danger" },
]
const hall_ticket_type = [
    { value: 1, text: "Regular" },
    { value: 2, text: "Arrear" }
]

const user_block_status = [ // used in staff and studeng block list page
    { value: 0, text: "Released" },
    { value: 1, text: "Blocked" }
]
const simple_status = [
    { value: 0, text: 'Deactivated' },
    { value: 1, text: 'Activated' }
]

const LOP = [
    { value: 1, text: "Yes", cssClass: "badge badge-success", editAction: true, },
    { value: 0, text: "No", cssClass: "badge badge-danger", editAction: false, },
]
const Hostel_AC = 175000
const Hostel_Non_AC = 80000
const CONST_IDENTITY_TYPE_ADMISSION = 1
const CONST_IDENTITY_TYPE_STAFF = 2;
const CONST_IDENTITY_TYPE_ADMIN = 0;
const CONST_IDENTITY_TYPE_STUDENT = 3;
const CONST_IDENTITY_TYPE_PARENT = 4;
const CONST_IDENTITY_TYPE_VISITOR = 5;
const CONST_PERMANENT_ADDRESS_TYPE = 1
const CONST_PRESENT_ADDRESS_TYPE = 2
const CONST_GUARDIAN_ADDRESS_TYPE = 3
const YEAR_FILTER_START = 2020;
const STUDENT_YEAR_START = 2017;

// For user access rights
const CONST_VIEW_ACCESS = 0;
const CONST_CREATE_ACCESS = 1;
const CONST_MODIFY_ACCESS = 2;
const CONST_DELETE_ACCESS = 3;
const CONST_ACCESS_LIMIT = 4;
const Const_Alert_pop_title = "Insufficient Data..."
const Const_Alert_pop_message = "Please fill in all the required fields.";

// 
const CONST_BIOMETRIC_NO_OUT_TIME_MAIL = 1;
const CONST_BIOMETRICL_NOT_ATTENDED_MAIL = 2;

const temp_import_columns = [
    { value: 1, text: "Staff Name" },
    { value: 2, text: "LOP Count" },
    { value: 3, text: "Gift" },
    { value: 4, text: "Other Deductions" },
    { value: 5, text: "Part Time Salary" },
    { value: 6, text: "Higher Studies" },
    { value: 7, text: "TDS" }
]

const address_types = [
    { title: 'Permanent Address', ADDRESS_TYPE: 1 },
    { title: 'Present Address', ADDRESS_TYPE: 2 },
    { title: 'Guardian\'s Address', ADDRESS_TYPE: 3 }
]

const user_login_type = [
    { value: 0, text: "Admin", header: 'Enter your username and password to access admin panel.' },
    { value: 1, text: "Staff", header: 'Enter your email and password to access staff panel.' },
    { value: 2, text: "Student", header: 'Enter your RegNo and DOB to access student panel.' },
    { value: 3, text: "New Admission", header: 'Enter your username and password to access admission panel.' },
    { value: 4, text: "Parents",header: 'Enter Student RegNo and Password to access student panel.' }
]

const calender_clr = [
    { value: 7, bgclr: "brown" },
    { value: 6, bgclr: "orange" },
    { value: 5, bgclr: "#00ccff" },
    { value: 4, bgclr: "#3399ff" },
    { value: 3, bgclr: "#ff66cc" },
    { value: 2, bgclr: "#9966ff" },
    { value: 1, bgclr: "#ffcc00" }
]

const staff_category = [
    { value: 1, text: "Teaching" },
    { value: 2, text: "Non-teaching" },
]

const grievance_status = [
    { value: 1, text: "Open" },
    { value: 2, text: "Completed" },
]
const identity_type = [
    { value: 1, text: "New Admission" },
    { value: 2, text: "Staff" },
    { value: 3, text: "Student" },
]
const PTApplicable = [
    { value: 1, text: "Yes" },
    { value: 0, text: "No" },
]

const IndexedIn = [ // arr to display value in select statement
    { value: 1, IndexName: "Scopus", text: "Scopus" },
    { value: 2, IndexName: "Web of Science", text: "Web of Science" },
    { value: 3, IndexName: "Google Scholar", text: "Google Scholar" },
    { value: 4, IndexName: "PUBMED ", text: "PUBMED " },
    { value: 5, IndexName: "UGC-CARE ", text: "UGC-CARE " }
]


const ResearchScholarStatus = [
    { value: 1, StatusName: "Provisionally Registered", text: "Provisionally Registered" },
    { value: 2, StatusName: "Provisionally Confirmed", text: "Provisionally Confirmed" },
    { value: 3, StatusName: "Synopsis Stage", text: "Synopsis Stage" },
    { value: 4, StatusName: "Awarded", text: "Awarded" },
    { value: 5, StatusName: "Under Extension", text: "Under Extension" },
    { value: 6, StatusName: "Thesis Stage", text: "Thesis Stage" },
]

const PatentStatus = [
    { value: 1, Name: "Published" },
    { value: 2, Name: "Granted" },
    { value: 3, Name: "Filed" }
]

const MembershipType = [
    { value: 0, Name: "Life Time" },
    { value: 1, Name: "Annual" },
    { value: 2, Name: "Student" }
]
const GuideshipType = [
    { value: 0, Name: "-" },
    { value: 1, Name: "Full Time" },
    { value: 2, Name: "Part Time" },
]

const Week = [
    { value: 1, Name: "Monday" },
    { value: 2, Name: "Tuesday" },
    { value: 3, Name: "Wednesday" },
    { value: 4, Name: "Thursday" },
    { value: 5, Name: "Friday" },
    { value: 6, Name: "Saturday" },
    { value: 7, Name: "Sunday" }
]

const Mins = [
    { value: "", Name: "00" },
    { value: '15', Name: "15" },
    { value: '30', Name: "30" },
    { value: '45', Name: "45" },
    { value: '60', Name: "60" },
]
const StartingMins = [
    { value: "00", Name: "00" },
    { value: '15', Name: "15" },
    { value: '30', Name: "30" },
    { value: '45', Name: "45" },
    { value: '60', Name: "60" },
]
const CalculationMins = [
    { value: '', Name: "00" },
    { value: '15', Name: "15 mins" },
    { value: '30', Name: "30 mins" },
    { value: '45', Name: "45 mins" },
    { value: '60', Name: "1 hour" },
    { value: '90', Name: "1.5 hours" },
]

const feeRange = [
    { value: '0', Name: "0 to 25,000", from: '0', to: '25000' },
    { value: '1', Name: "25,001 to 50,000", from: '25001', to: '50000' },
    { value: '2', Name: "50,001 to 75,000", from: '50001', to: '75000' },
    { value: '3', Name: "75,000 to 1,00,000", from: '75001', to: '100000' },
    { value: '4', Name: "Below 1,00,000", from: '100000' },
    { value: '5', Name: "Above 1,00,000", from: '100000' },
]

const paymentreportfeeRange = [
    { value: '0', Name: "0 to 25,000", from: '0', to: '25000' },
    { value: '1', Name: "25,001 to 50,000", from: '25001', to: '50000' },
    { value: '2', Name: "50,001 to 75,000", from: '50001', to: '75000' },
    { value: '3', Name: "75,001 to 1,00,000", from: '75001', to: '100000' },
    { value: '4', Name: "1,00,001 to  1,25,000", from: '100001', to: '125000' },
    { value: '5', Name: "1,25,001 to 1,50,000", from: '125001', to: '150000' },
    { value: '6', Name: "1,50,001 to 1,75,000", from: '150001', to: '175000' },
    { value: '7', Name: "1,75,001 to 2,00,000", from: '175001', to: '200000' },
    { value: '8', Name: "Above 2,00,001", from: '200001' },
]
const salaryRange = [
    { value: '1', Name: "0 to 50,000", from: '0', to: '50000' },
    { value: '2', Name: "50,001 to 75,000", from: '50001', to: '75000' },
    { value: '3', Name: "75,001 to 1,00,000", from: '75001', to: '100000' },
    { value: '4', Name: "1,00,001 to 1,25,000", from: '100001', to: '125000' },
    { value: '5', Name: "1,25,001 to 1,50,000", from: '125001', to: '150000' },
    { value: '6', Name: "Above 1,50,001", from: '150001' },
]

const CAEType = [
    { value: '1', Name: "CAE 1" },
    { value: '2', Name: "CAE 2" },
]
const TypeofStudy = [
    { value: '1', text: "Part Time" },
    { value: '2', text: "Full Time" }
]
const AcademicMonth = [
    { value: '1', text: "June/July" },
    { value: '2', text: "December/January" }
]


// obj used in employee-detail @method editseed-money , edit-startup , edittbi
const Pubmodules = {
    Seedmoney: 1,
    StartUp: 2,
    TBI: 3
}

const booleanYesNo = [
    { value: 1, text: "Yes" },
    { value: 0, text: "No" },
]

const HostelType = [
    { value: 1, text: "AC" },
    { value: 0, text: "Non AC" },
]
const HostelCategory = [
    { value: 1, text: "STUDENT" },
    { value: 2, text: "STAFF" },
    { value: 3, text: "STORE" },
    { value: 4, text: "OFFICE" },
    { value: 5, text: "LAUNDRY" },
    { value: 6, text: "GUEST" },
    { value: 7, text: "CIVIL" }
]
const VisitorsType = [
    { value: 1, name: 'Vendor' },
    { value: 2, name: 'Parent' },
    { value: 3, name: 'Guardian' },
    { value: 4, name: 'Vehicle' },
    { value: 5, name: 'Admission Enquiry' },
    { value: 6, name: 'Others' }
]
const patentTypeList = [
    { value: 1, name: 'Design' },
    { value: 2, name: 'Product' },
    { value: 3, name: 'Process' },
    { value: 4, name: 'Trade Mark' },
    { value: 5, name: 'Copyright' },


]

//Events
const eventType = [
    { value: 1, name: 'Seminar' },
    { value: 2, name: 'Conference' },
    { value: 3, name: 'Symposium' },
    { value: 4, name: 'Workshop' },
    { value: 5, name: 'Guest Lecture' },

]
const eventBy = [
    { value: 1, name: 'Organized' },
    { value: 2, name: 'Resource Person' },
]
const funded = [
    { value: 0, name: 'No' },
    { value: 1, name: 'Yes' }]
const GuideshipStatus = [
    { value: 0, name: 'No' },
    { value: 1, name: 'Yes' }]

const RemarkTypes = [
    { value: 1, name: 'Appreciation' },
    { value: 2, name: 'Negative' }
]
const RemarkStatus = [
    { value: 0, name: 'Closed' },
    { value: 1, name: 'Open' }
]
const FeedbackSuggestionTypes = [
    { value: 1, name: 'Academics' },
    { value: 2, name: 'Infrastructure' },
    { value: 3, name: 'Transport' },
    { value: 4, name: 'Food' },
    { value: 5, name: 'Any Other' }
]

const HostelFeedbackSuggestionTypes = [
    { value: 6, name: 'Hostel - Electrical Problem' },
    { value: 7, name: 'Hostel - Plumbing Problem' },
    { value: 8, name: 'Hostel - Carpentry Problem' },
    { value: 9, name: 'Hostel - General Problem' }
]

const FeedbackSuggestionPipe = [
    { value: 1, name: 'Academics' },
    { value: 2, name: 'Infrastructure' },
    { value: 3, name: 'Transport' },
    { value: 4, name: 'Food' },
    { value: 5, name: 'Any Other' },
    { value: 6, name: 'Hostel - Electrical Problem' },
    { value: 7, name: 'Hostel - Plumbing Problem' },
    { value: 8, name: 'Hostel - Carpentry Problem' },
    { value: 9, name: 'Hostel - General Problem' },
    { value: 10,  name: 'Internship' },
    { value: 11,  name: 'LMS' },
    { value: 12,  name: 'ERP' },
    { value: 13,  name: 'Library' },
    { value: 14,  name: 'Internal Examination' },
    { value: 15,  name: 'End Semester Examination' }
]



const studentExtraCurricular = [

    { extraCurricular: 1, extraCurricularName: 'Sports Man' },
    { extraCurricular: 2, extraCurricularName: 'NCC' },
    { extraCurricular: 3, extraCurricularName: 'YRC' },
    { extraCurricular: 4, extraCurricularName: 'Student Club' },
    { extraCurricular: 5, extraCurricularName: 'NSS' }
]
const marks = [
    { value: 1, name: 'Above 90 Marks' },
    { value: 2, name: '80-89 Marks' },
    { value: 3, name: '70-79 Marks' },
    { value: 4, name: '60-69 Marks' },
    { value: 5, name: 'Below 60' },
]
const board = [
    { value: 1, name: 'CBSE' },
    { value: 2, name: 'ICSE' },
    { value: 3, name: 'State Board' }

]
const phD_Streams = [
    { value: 0, name: 'Engineering' },
    { value: 1, name: 'Management' },
    { value: 2, name: 'Science and Humanities' },
    { value: 3, name: 'Arts' },
    { value: 4, name: 'Law' },
    { value: 5, name: 'Medicine' },
    { value: 6, name: 'Architecture' },
    { value: 7, name: 'Pharmacy' },
    { value: 8, name: 'Nursing' },
]

const degrees = [
    { value: 1, name: 'UG' },
    { value: 2, name: 'PG' },
    { value: 6, name: 'Doctorate' },

]
const student_admission_type = [

    { AdmissionType: 1, AdmissionTypeName: "DIRECT" },
    { AdmissionType: 2, AdmissionTypeName: "DEPROMOTED" },
    { AdmissionType: 3, AdmissionTypeName: "LATERAL ENTRY" },
    { AdmissionType: 4, AdmissionTypeName: "TRANSFER" },

]
const student_admission_subtypes = [

    { AdmissionSubType: 1, AdmissionSubTypeName: "COUNSELLING" },
    { AdmissionSubType: 2, AdmissionSubTypeName: "SCHOLARSHIP" },
    { AdmissionSubType: 3, AdmissionSubTypeName: "CPD" },
    { AdmissionSubType: 4, AdmissionSubTypeName: "MERIT" },
    { AdmissionSubType: 5, AdmissionSubTypeName: "MANAGEMENT" },
    { AdmissionSubType: 6, AdmissionSubTypeName: "SPORTS" }

]
const student_admission_categories = [

    { AdmissionCategories: 1, AdmissionCategoriesName: "REGULAR" },
    { AdmissionCategories: 2, AdmissionCategoriesName: "ODL" },
    { AdmissionCategories: 3, AdmissionCategoriesName: "PARTTIME" },

]

const vehicleUsingBy = [
    { value: 1, name: 'President' },
    { value: 2, name: 'Chancellor' },
    { value: 3, name: 'Vice President - Sir' },
    { value: 4, name: 'Vice President - Madam' },
    { value: 5, name: 'Vice Chancellor' },
    { value: 6, name: 'Pro Vice Chancellor' },
    { value: 7, name: 'Registrar' },
    { value: 8, name: 'Director - Admin' },
    { value: 9, name: 'Director - Research' },
    { value: 10, name: 'Accounts' },
    { value: 11, name: 'Dean - Placement' },
    { value: 12, name: 'COE' },
    { value: 13, name: 'Transport - Manager' },
    { value: 14, name: 'Legal Advisor' },
    { value: 15, name: 'MIT Principal' },
    { value: 16, name: 'Mr.Prabhakar' },
    { value: 17, name: 'AMMA' },
    { value: 18, name: 'Students' },
    { value: 19, name: 'Staff' },
    { value: 20, name: 'Others' },


]
const HostelGenType = [
    { value: 1, name: 'Boys Hostel' },
    { value: 2, name: 'Girls Hostel' }
]
const vehicleSearchType = [
    { value: 'PermitDate', name: 'PERMIT' },
    { value: 'FCDate', name: 'FC Date' },
    { value: 'FCRenewalDate', name: 'FC Renewal Date' },
    { value: 'TaxDate', name: 'TAX' },
    { value: 'InsuranceDate', name: 'Insurance' },
    { value: 'ThirdPartExpiryDate', name: 'Expiry - 3rd party' }
]
const productUnit = [
    { value: 1, name: 'Pcs' },
    { value: 2, name: 'Kg' }
]
const productType = [
    { value: 1, name: 'P1' },
    { value: 2, name: 'P2' }
]
const achievementEventType = [
    { value: 1, name: 'National' },
    { value: 2, name: 'International' },
]
const routeTypeList = [
    { RouteTypeId: 1, RouteTypeName: 'In' },
    { RouteTypeId: 2, RouteTypeName: 'Out' },
]
const RouteType = [
    { value: 1, name: 'IN' },
    { value: 2, name: 'OUT' }
]
const RouteStatus = [
    { value: 1, name: 'ACTIVE' },
    { value: 0, name: 'INACTIVE' }
]
const RouteCategory = [
    { value: 1, name: 'Student' },
    { value: 2, name: 'Staff' },
    { value: 3, name: 'Both' }
]
const PlacementExams = [
    { value: 1, text: 'Placement' },
    { value: 2, text: 'Higher Studies' },
    { value: 3, text: 'Both' }
]
const InterviewLevels = [
    { value: 1, text: 'Written' },
    { value: 2, text: 'Technical Round' },
    { value: 3, text: 'Group Discussion' },
    { value: 4, text: 'HR' }
]
const TransportAttendanceStatus = [
    { value: 1, text: 'On Time' },
    { value: 2, text: 'Delay' }
]

const SubjectAllocationType = [
    { value: 0, text: 'No' },
    { value: 1, text: 'Yes' }
]
const semseter = [
    1, 2, 3, 4, 5, 6, 7, 8
]
const semseter1 = [
    1, 2
]
const semseter2 = [
    3, 4
]
const semseter3 = [
    5, 6
]
const semseter4 = [
    7, 8
]
const semseter5 = [
    9, 10
]


const yearofstudy = [
    1, 2, 3, 4
]
const yearofstudy1 = [
    1, 2, 3
]
const yearofstudyArts = [
    1, 2, 3
]
const yearofstudyBE = [
    1, 2, 3, 4
]
const yearofstudyPG = [
    1, 2
]
const Admission_On_abroad = [
    { value: '1', text: 'USA' },
    { value: '2', text: 'UK' },
    { value: '3', text: 'Australia' },
    { value: '4', text: 'New Zealand' },
    { value: '5', text: 'Singapore' },
    { value: '6', text: 'Germany' },
    { value: '7', text: 'Sweden' },
    { value: '8', text: 'Canada' },
    { value: '9', text: 'Others' },
]


const Event_Calander_Identity_Type = [
    { value: 0, text: 'Both' },
    { value: 2, text: 'Staff' },
    { value: 3, text: 'Student' },
]

//Notifications
const identityTypeList = [
    { value: 1, name: 'Admission' },
    { value: 2, name: 'Staff' },
    { value: 3, name: 'Student' },
    { value: 4, name: 'Parent'}
]

const QRFeedbackTypeList = [
    { FeedbackTypeId: 2, FeedbackTypeName: 'Staff' },
    { FeedbackTypeId: 3, FeedbackTypeName: 'Student' },
    { FeedbackTypeId: 4, FeedbackTypeName: 'Parent' },
    { FeedbackTypeId: 5, FeedbackTypeName: 'Visitor'},
    { FeedbackTypeId: 6, FeedbackTypeName: 'General'}
]

const placement_status = [
    { value: 1, text: "Register" },
    { value: 2, text: "Apply" },
    { value: 3, text: "Present" },
]
const interviewResult = [
    { value: 1, text: "Pass" },
    { value: 2, text: "Fail" }
]

const feedbackStatus = [
    { value: 0, text: "New" },
    { value: 1, text: "Closed" }
]
const placementTrainingTypes = [
    { value: 1, text: "Placement" },
    { value: 2, text: "Higher Study" },
    { value: 3, text: "Placement & Higher Study" },
]
const fiveMonth = [
    { value: 1, text: "January" },
    { value: 2, text: "February" },
    { value: 3, text: "March" },
    { value: 4, text: "April" },
    { value: 5, text: "May" },
]
const SubjectType = [
    { value: 1, text: "Core" },
    { value: 2, text: "Elective" },

]
const Years = [
    { value: 1, Year: 1 },
    { value: 2, Year: 2 },
    { value: 3, Year: 3 },
    { value: 4, Year: 4 },
    { value: 5, Year: 5 }
]
const Semesters = [
    { value: 1, year: 1, semester: 1 },
    { value: 2, year: 1, semester: 2 },
    { value: 3, year: 2, semester: 3 },
    { value: 4, year: 2, semester: 4 },
    { value: 5, year: 3, semester: 5 },
    { value: 6, year: 3, semester: 6 },
    { value: 7, year: 4, semester: 7 },
    { value: 8, year: 4, semester: 8 },
    { value: 9, year: 5, semester: 9 },
    { value: 10, year: 5, semester: 10 }
]

const auditorium_booking_status = [
    { value: 0, text: "Open" },
    { value: 1, text: "Approved" },
    { value: 2, text: "Rejected" }
]
const admin_auditorium_booking_status = [
    { value: 1, text: "Approved" },
    { value: 2, text: "Rejected" }
]
const semesterForPG = [
    1, 2, 3, 4, 5, 6
]
const hostel_student_attendance_status = [
    { value: 0, text: "Absent",  cssClass: "badge badge-danger"},
    { value: 1, text: "Present", cssClass: "badge badge-success" }
]
const SurveyQuestion_Types = [
    { value: 'Dropdown',QuestionType:1,viewValue: 'Dropdown' },
    { value: 'Multi choice',QuestionType:2,viewValue: 'Multi choice' },
    { value: 'Paragraph',QuestionType:3,viewValue: 'Paragraph' },
    { value: 'Radio Button',QuestionType:4,viewValue: 'Radio Button' },
    { value: 'Checkbox',QuestionType:5,viewValue: 'Checkbox' }
]
const QRFeedbackQuestion_Types = [
    // { value: 'Dropdown',QuestionType:1,viewValue: 'Dropdown' },
    { value: 'Multi choice',QuestionType:2,viewValue: 'Multi choice' },
    { value: 'Paragraph',QuestionType:3,viewValue: 'Paragraph' },
    { value: 'Radio Button',QuestionType:4,viewValue: 'Radio Button' },
    // { value: 'Checkbox',QuestionType:5,viewValue: 'Checkbox' }
    { value: 'Star',QuestionType:6,viewValue: 'Star' },
    { value: 'Upload',QuestionType:7,viewValue: 'Upload' }
]

const Elective_Options = [
    { value: 1,name:'Elective' },
    { value: 2,name:'Open Elective' },
    { value: 3,name:'CBCS' },
]

const Gatepass_Type = [
    {value:1,text:'Day Out',Daysallowed:0},
    {value:2,text:'Home Out',Daysallowed:4}
]

const Staff_Reports_Module_List = [
    
    {   
        value : 0,
        name : "Journals",
        fields :[
            {id : "Staff Name" , name : "StaffName" },
            {id : "Staff Code" , name : "StaffCode" },
            {id : "Staff Department" , name : "DepartmentName" },
            {id : "Author Name" , name : "Authors" },
            {id : "Article Title" , name : "ArticleTitle" },
            {id : "Journal Name" , name : "JournalName" },
            {id : "Publishing House" , name : "PublishingHouse" },
            {id : "Page No From" , name : "PageNoFrom" },
            {id : "Page No To" , name : "PageNoTo" },
            {id : "Month Of Publication" , name : "MonthOfPublication" },
            {id : "Year Of Publication" , name : "YearOfPublication" },
            {id : "Impact Factor" , name : "ImpactFactor" },
            {id : "Indexed In" , name : "IndexedIn" },
            {id : "DOI" , name : "DOI" },
            {id : "Volume Number" , name : "VolumeNumber" },
            {id : "Issue Number" , name : "IssueNumber" },
            {id : "Citation Count" , name : "CitationCount" },
            {id : "Corresponding Author" , name : "CorrespondingAuthor" },
            {id : "Other Author" , name : "OtherAuthor" },
        ]   
    },
    {   
        value : 1,
        name : "Conference",
        fields :[
            {id : "Staff Name" , name : "StaffName" },
            {id : "Staff Code" , name : "StaffCode" },
            {id : "Staff Department" , name : "DepartmentName" },
            {id : "Author Name" , name : "Authors" },
            {id : "Corresponding Author" , name : "CorrespondingAuthor" },
            {id : "Other Author" , name : "OtherAuthor" },
            {id : "Article Title " , name : "ArticleTitle" },
            {id : "Name of the Conference" , name : "ConferenceName" },
            {id : "Year of Publication" , name : "YearOfPublication" },
            {id : "Date of Conference (From)" , name : "ConferenceFromDate" },
            {id : "Date of Conference (To)" , name : "ConferenceToDate" },
            {id : "Organized by" , name : "OrganizedBy" },
            {id : "Page No. From" , name : "PageNoFrom" },
            {id : "Page No. To" , name : "PageNoTo" },
            {id : "Month of publication" , name : "MonthOfPublication" },
            {id : "Indexed in" , name : "IndexedIn" },
            {id : "Citation Count" , name : "CitationCount" },
            {id : "Impact Factor" , name : "ImpactFactor" },
            {id : "DOI" , name : "DOI" },
            {id : "Volume Number" , name : "VolumeNumber" },
            {id : "Issue Number " , name : "IssueNumber" },
        ]   
    },
    {   
        value : 2,
        name : "Books",
        fields :[
            {id : "Staff Name" , name : "StaffName" },
            {id : "Staff Code" , name : "StaffCode" },
            {id : "Staff Department" , name : "DepartmentName" },
            {id : "Author Name" , name : "AuthorName" },
            {id : "Other Author Name" , name : "OtherAuthors" },
            {id : "Book Title" , name : "BookTitle" },
            {id : "Published By" , name : "PublishedBy" },
            {id : "Publishing Year" , name : "PublishingYear" },
            {id : "ISSN No" , name : "ISSNNo" },
            {id : "ISBN No" , name : "ISBNNo" },
            {id : "Editor Name" , name : "EditorName" },
            {id : "Month of Publication" , name : "PublicationMonth" },
            {id : "Book Webpage" , name : "BookWebpage" },
            {id : "Corresponding Author" , name : "CorrespondingAuthor" },
            
        ]   
    },
    {   
        value : 3,
        name : "Book Chapters",
        fields :[
            {id : "Staff Name" , name : "StaffName" },
            {id : "Staff Code" , name : "StaffCode" },
            {id : "Staff Department" , name : "DepartmentName" },
            {id : "Author Name" , name : "AuthorName" },
            {id : "Corresponding Author" , name : "CorrespondingAuthor" },
            {id : "Book Title" , name : "BookTitle" },
            {id : "Other Author" , name : "OtherAuthor" },
            {id : "Chapter Title" , name : "ChapterTitle" },
            {id : "Page No From" , name : "PageNoFrom" },
            {id : "Page No To" , name : "PageNoTo" },
            {id : "Published By" , name : "PublishedBy" },
            {id : "Publishing Year" , name : "PublishingYear" },
            {id : "ISSN No" , name : "ISSNNo" },
            {id : "ISBN No" , name : "ISBNNo" },
            
        ]   
    },
    {   
        value : 4,
        name : "Monograph",
        fields :[
            {id : "Staff Name" , name : "StaffName" },
            {id : "Staff Code" , name : "StaffCode" },
            {id : "Staff Department" , name : "DepartmentName" },
            {id : "Author Name" , name : "Authors" },
            {id : "Monograph Title" , name : "MonographTitle" },
            {id : "Publisher Name" , name : "Publisher" },
            {id : "Page No From" , name : "PageNoFrom" },
            {id : "Page No To" , name : "PageNoTo" },
            {id : "Monograph Date" , name : "MonographDate" },
            {id : "Corresponding Author" , name : "CorrespondingAuthor" },
            {id : "Other Author" , name : "OtherAuthor" },
            
            
            
        ]   
    },
    {   
        value : 5,
        name : "Patents",
        fields :[
            {id : "Staff Name" , name : "StaffName" },
            {id : "Staff Code" , name : "StaffCode" },
            {id : "Staff Department" , name : "DepartmentName" },
            {id : "Author Name" , name : "Authors" },
            {id : "Patent Title" , name : "PatentTitle" },
            {id : "Patent No" , name : "PatentNo" },
            {id : "Filing Date" , name : "FilingDate" },
            {id : "Status" , name : "PatentStatus" },
            {id : "Publishing Date" , name : "PublishingDate" },
            {id : "Patent Type" , name : "PatentType" },
            {id : "Other Investors" , name : "OtherInventors" },
            
            
            
        ]   
    },
    {   
        value : 6,
        name : "Awards",
        fields :[
            {id : "Staff Name" , name : "StaffName" },
            {id : "Staff Code" , name : "StaffCode" },
            {id : "Staff Department" , name : "DepartmentName" },
            {id : "Award Title" , name : "AwardTitle" },
            {id : "Awarded By" , name : "AwardedBy" },
            {id : "Award Date" , name : "AwardDate" }
        ]   
    },
    {   
        value : 7,
        name : "Seed Money",
        fields :[
            {id : "Staff Name" , name : "StaffName" },
            {id : "Staff Code" , name : "StaffCode" },
            {id : "Staff Department" , name : "DepartmentName" },
            {id : "PI" , name : "PI" },
            {id : "Co PI" , name : "CoPI" },
            {id : "Proposal Title" , name : "ProposalTitle" },
            {id : "Sanction Date" , name : "DateofSanction" },
            {id : "Sanction Amount" , name : "SanctionedAmount" },
            {id : "Funded By" , name : "FundedBy" },
        ]   
    },
    {   
        value : 8,
        name : "StartUps",
        fields :[
            {id : "Staff Name" , name : "StaffName" },
            {id : "Staff Code" , name : "StaffCode" },
            {id : "Staff Department" , name : "DepartmentName" },
            {id : "PI" , name : "PI" },
            {id : "Co PI" , name : "CoPI" },
            {id : "Proposal Title" , name : "ProposalTitle" },
            {id : "Sanction Date" , name : "DateofSanction" },
            {id : "Sanction Amount" , name : "SanctionedAmount" },
            {id : "Funded By" , name : "FundedBy" },
        ]   
    },
    {   
        value : 9,
        name : "Professional Membership",
        fields :[
            {id : "Staff Name" , name : "StaffName" },
            {id : "Staff Code" , name : "StaffCode" },
            {id : "Staff Department" , name : "DepartmentName" },
            {id : "Professional Name" , name : "ProfessionalName" },
            {id : "Valid From" , name : "ValidFromDate" },
            {id : "Valid To" , name : "ValidToDate" },
            {id : "Membership No" , name : "MembershipNo" },
            {id : "Membership Type" , name : "MembershipType" }
        ]   
    },
    {   
        value : 10,
        name : "Reviewer",
        fields :[
            {id : "Staff Name" , name : "StaffName" },
            {id : "Staff Code" , name : "StaffCode" },
            {id : "Staff Department" , name : "DepartmentName" },
            {id : "Journal Name" , name : "JournalName" },
            {id : "Index In" , name : "IndexedIn" },
            {id : "ISSNNo" , name : "ISSNNo" },
            {id : "Number of Times Reviewed" , name : "NoofTimesReviewed" },
            {id : "Month of Review" , name : "ReviewMonth" },
            {id : "Year of Review" , name : "ReviewYear" }
        ]   
    },
    {   
        value : 11,
        name : "Research Guidance",
        fields :[
            {id : "Staff Name" , name : "StaffName" },
            {id : "Staff Code" , name : "StaffCode" },
            {id : "Staff Department" , name : "DepartmentName" },
            {id : "Register Number" , name : "RegisterNumber" },
            {id : "Organization Name" , name : "OrganizationName" },
            {id : "Candidate Name" , name : "CandidateName" },
            {id : "Status" , name : "Status" },
            {id : "Registration Month" , name : "ResearchScholarsMonth" },
            {id : "Registration Year" , name : "ResearchScholarsYear" },
            {id : "Under Extension" , name : "UnderExtension" },
            {id : "Thesis Stage" , name : "ThesisStage" },
            {id : "Thesis Title" , name : "ThesisTitle" },
            {id : "GuideShip Type" , name : "GuideShipType" },
            {id : "Award Date" , name : "AwardDate" },
        ]   
    },
    {   
        value : 12,
        name : "Consultancy Projects",
        fields :[
            {id : "Staff Name" , name : "StaffName" },
            {id : "Staff Code" , name : "StaffCode" },
            {id : "Staff Department" , name : "DepartmentName" },
            {id : "Consultancy Projects" , name : "ConsultancyProjects" },
            {id : "Organization Name" , name : "OrganizationName" },
            {id : "Consultant Name" , name : "ConsultantName" },
            {id : "Project Title" , name : "ProjectTitle" },
            {id : "Organization Name" , name : "OrganizationName" },
            {id : "Sanction Date" , name : "SanctionDate" },
            {id : "Sanction Reference" , name : "SanctionReference" },
            {id : "Duration" , name : "Duration" },
            {id : "Consultancy Amount" , name : "ConsultancyAmount" }
        ]   
    } , 
    {   
        value : 13,
        name : "International Fellowships",
        fields :[
            {id : "Staff Name" , name : "StaffName" },
            {id : "Staff Code" , name : "StaffCode" },
            {id : "Staff Department" , name : "DepartmentName" },
            {id : "Fellowship Name" , name : "FellowshipName" },
            {id : "Santioned Year" , name : "SanctionedYear" },
            {id : "From Date" , name : "FromDate" },
            {id : "To Date" , name : "ToDate" },
            {id : "Institution Name" , name : "InstitutionName" },
            {id : "Country" , name : "Country" }
        ]   
    },

    {   
        value : 14,
        name : "Funds / Grants Reviewer",
        fields :[
            {id : "Staff Name" , name : "StaffName" },
            {id : "Staff Code" , name : "StaffCode" },
            {id : "Staff Department" , name : "DepartmentName" },
            {id : "Funding Agency" , name : "FundingAgency" },
            {id : "Project Title" , name : "ProjectTitle" },
            {id : "Project Reviewer Month" , name : "ProjectReviewerMonth" },
            {id : "Project Reviewer Year" , name : "ProjectReviewerYear" }
        ]   
    } ,
    {   
        value : 15,
        name : "International Travel",
        fields :[
            {id : "Staff Name" , name : "StaffName" },
            {id : "Staff Code" , name : "StaffCode" },
            {id : "Staff Department" , name : "DepartmentName" },
            {id : "Date From" , name : "fromDate" },
            {id : "Date To" , name : "toDate" },
            {id : "Supported by" , name : "SponsoredBy" },
            {id : "Institution / Organization Name " , name : "Organization" },
            {id : "Country" , name : "Country" },
            {id : "Event Name" , name : "Event" }
        ]   
    },
    {   
        value : 16,
        name : "Certificates",
        fields :[
            {id : "Staff Name" , name : "StaffName" },
            {id : "Staff Code" , name : "StaffCode" },
            {id : "Staff Department" , name : "DepartmentName" },
            {id : "Certificate Type" , name : "CertificateTypeName" },
            {id : "Certificate Name" , name : "CertificateName" },
            {id : "From Date" , name : "FromDate" },
            {id : "To Date" , name : "ToDate" }
        ]   
    },
    {   
        value : 17,
        name : "Youtube Lectures",
        fields :[
            {id : "Staff Name" , name : "StaffName" },
            {id : "Staff Code" , name : "StaffCode" },
            {id : "Staff Department" , name : "DepartmentName" },
            {id : "Topic Name" , name : "Topic" },
            {id : "Upload Date" , name : "VideoUploadDate" },
            {id : "View Count" , name : "ViewCount" }
        ]   
    }



]
const shotmonths = [
    {value:1,stext:'Jan',text:'January',fc:'JAN'},
    {value:2,stext:'Feb',text:'Febrary',fc:'FEB'},
    {value:3,stext:'Mar',text:'March',fc:'MAR'},
    {value:4,stext:'Apr',text:'April',fc:'APR'},
    {value:5,stext:'May',text:'May',fc:'MAY'},
    {value:6,stext:'Jun',text:'June',fc:'JUN'},
    {value:7,stext:'Jul',text:'July',fc:'JUL'},
    {value:8,stext:'Aug',text:'August',fc:'AUG'},
    {value:9,stext:'Sep',text:'September',fc:'SEP'},
    {value:10,stext:'Oct',text:'October',fc:'OCT'},
    {value:11,stext:'Nov',text:'November',fc:'NOV'},
    {value:12,stext:'Dec',text:'December',fc:'DEC'}
]

const staffbiometrictimings = [
    { value: 1, text: "On-Time", cssClass: "badge badge-success" },
    { value: 2, text: "Late Arrival", cssClass: "badge badge-warning" },
    { value: 3, text: "Early Departure", cssClass: "badge badge-danger" },
    { value: 4, text: "Late Arrival & Early Depature", cssClass: "badge badge-primary" },
    { value: 5, text: "Leave", cssClass: "badge badge-warning" },
    { value: 6, text: "Holiday", cssClass: "badge badge-info"}
] 
const staff_permission_timings = [
    {value:1,text:'1hr Permission',cssClass: "badge badge-primary"},
    {value:2,text:'2hr Permission',cssClass: "badge badge-secondary"},
    {value:3,text:'Halfday Leave',cssClass: "badge badge-warning"},
    {value:4,text:'OutTime not found',cssClass: "badge badge-danger"},
    {value:5,text:'On-Time',cssClass: "badge badge-success"},
    {value:6,text:'Leave',cssClass: "badge badge-warning"},
    {value:7,text:'Saturday',cssClass: "badge badge-info"},
    {value:8,text:'Sunday',cssClass: "badge badge-info"},
    {value:9,text:'Holiday',cssClass: "badge badge-success" },
    {value:10,text:'Mismatch', cssClass:"badge badge-danger"}


]
const bioMetricDirection = [
    { value: 1, name: 'IN' },
    { value: 0, name: 'OUT' }
]

const LogInOTPStatus = [
    { value: 1, name: 'Yes' },
    { value: 0, name: 'No' }
]
const HostelBiometricStatus = [
    
    { value: 1, text:'Not Attended', cssClass: "badge badge-danger"},
    { value: 2, text:'Leave', cssClass: "badge badge-warning"},
    { value: 3, text:'Permission', cssClass: "badge badge-primary"},
    { value: 4, text:'Attended', cssClass: "badge badge-success" }
]

const ExamTypes = [
    {value:1,text:'CAE-1'},
    {value:2,text:'CAE-2'},
    {value:3,text:'ESC'}
]

const IdProof = [
    {value:1,text:'Aadhar Card'},
    {value:2,text:'Driving Licence'}
]

const DailyAttendanceTypes = [
    {value:0,text:'Absent',cssClass:"badge badge-danger"},
    {value:1,text:'OD',cssClass:"badge badge-success"}
]
const ProvideStatus = [
    {value:0,text:'Not-issued',cssClass:"badge badge-danger"},
    {value:1,text:'Issued',cssClass:"badge badge-success"}
]
const SchoolTypes = [
    {value:1,text:'School of Computing'},
    {value:2,text:'School of Science & Humanities'},
    {value:3,text:'School of Electrical and Electronics'},
    {value:4,text:'School of Law'},
    {value:5,text:'School of Mechanical'},
    {value:6,text:'School of Pharmacy'},
    {value:7,text:'School of Bio and Chemical Engineering'},
    {value:8,text:'School of Nursing'},
    {value:9,text:'School of Building and Environment'},
    {value:10,text:'School of Dental Sciences'},
    {value:11,text:'School of Management Studies'},
    {value:12,text:'School of Physiotherapy'}
]

const ParentOccupationType = [
    {value:1,text:'Private'},
    {value:2,text:'Government'},
    {value:3,text:'Business'},
    {value:4,text:'Other'},
]

const TimingsType = [
    {value:1,text:'08:00AM - 09:00AM'},
    {value:2,text:'09:00AM - 10:00AM'},
    {value:3,text:'10:15AM - 11:15AM'},
    {value:4,text:'11:15AM - 12:15PM'},
    {value:5,text:'01:15PM - 02:15PM'},
    {value:6,text:'02:15PM - 03:15PM'},
]
const StaffAttendanceStatus = [
    {value:1,text:'Not Reported'},
    {value:2,text:'late within 30 mins.'},
    {value:3,text:'late beyond 30 mins.'}
]
const isAdmin_module_enable = 1
const IS_FEES_ENABLE = 0;  // 0 - Disable, 1 - Enable


export {
    admission_categories, application_status, admission_type, community, nationality, religion, gender, mother_tongue, blood_group, payment_type,
    Hostel_AC, Hostel_Non_AC, CONST_PERMANENT_ADDRESS_TYPE, CONST_PRESENT_ADDRESS_TYPE, CONST_GUARDIAN_ADDRESS_TYPE, CONST_IDENTITY_TYPE_ADMISSION,
    staff_type, experience_type, YEAR_FILTER_START, months, monthName, CONST_IDENTITY_TYPE_STAFF, fee_paid_category, leave_status, staff_status, CONST_VIEW_ACCESS,
    CONST_CREATE_ACCESS, CONST_MODIFY_ACCESS, CONST_DELETE_ACCESS, CONST_ACCESS_LIMIT, Const_Alert_pop_title, Const_Alert_pop_message, temp_import_columns,
    LOP, department, staff_category, relieve_status, CONST_IDENTITY_TYPE_ADMIN, grievance_status, PTApplicable, IndexedIn, PatentStatus, Pubmodules, booleanYesNo, CONST_IDENTITY_TYPE_STUDENT
    , user_login_type, calender_clr, Week, Mins, CalculationMins, feeRange, application_status_without_Reject, HostelType, address_types, salaryRange, logType, CAEType, VisitorsType, menuItemType, messGenderAllowed, ResearchScholarStatus,
    eventType, eventBy, funded, patentTypeList, TypeofStudy, GuideshipStatus, AcademicMonth, MembershipType, GuideshipType, RemarkTypes, RemarkStatus, FeedbackSuggestionTypes, paymentreportfeeRange, STUDENT_YEAR_START,
    studentExtraCurricular, marks, board, phD_status, phD_Streams, degrees, hall_ticket_type, student_admission_type, student_admission_subtypes, student_admission_categories,
    vehicleUsingBy, semseter, yearofstudy, HostelGenType, identityTypeList, HostelCategory, vehicleSearchType, productUnit, productType, isAdmin_module_enable, achievementEventType, routeTypeList, RouteType, RouteStatus, RouteCategory, PlacementExams, InterviewLevels, StartingMins, SubjectAllocationType, Admission_On_abroad,
    TransportAttendanceStatus, Event_Calander_Identity_Type, placement_status, lor_satus, Hostel_Students_Type, identity_type, interviewResult, yearofstudyBE, yearofstudyPG, semseter1, semseter2, semseter3, semseter4, semseter5, yearofstudyArts, yearofstudy1, feedbackStatus, HostelFeedbackSuggestionTypes, FeedbackSuggestionPipe,
    placementTrainingTypes, user_block_status, fiveMonth, Semesters, Years, SubjectType, auditorium_booking_status, admin_auditorium_booking_status, simple_status, semesterForPG, SurveyQuestion_Types, hostel_student_attendance_status, Elective_Options,
    IS_FEES_ENABLE,Gatepass_Type, Staff_Reports_Module_List,shotmonths,staffbiometrictimings,staff_permission_timings, bioMetricDirection , LogInOTPStatus , CONST_BIOMETRIC_NO_OUT_TIME_MAIL , CONST_BIOMETRICL_NOT_ATTENDED_MAIL,HostelBiometricStatus,ExamTypes,IdProof,DailyAttendanceTypes,ProvideStatus,SchoolTypes,CURRENT_ADMISISON_BATCH,
    CONST_IDENTITY_TYPE_PARENT,CONST_IDENTITY_TYPE_VISITOR,ParentOccupationType,TimingsType,StaffAttendanceStatus, QRFeedbackQuestion_Types, QRFeedbackTypeList
}
