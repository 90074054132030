import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Input, OnChanges } from '@angular/core';
import MetisMenu from 'metismenujs/dist/metismenujs';
import { NavigationItem } from 'src/app/core/navigation';
import { DefaultNavigation } from 'src/app/core/navigation/app-navigation';
import { CommonService } from "../../core/services/common.service";

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges {

    @Input() isCondensed = false;
    menu: any;
    menulist: any[] =[];
    pathname;
    defaultRoutes: NavigationItem[];

    @ViewChild('sideMenu', { static: false }) sideMenu: ElementRef;

    constructor() {
        this.pathname = window.location.pathname;
        // this.getMenuList();
        if (window.location.pathname.includes("hotels")) {
            this.menulist = DefaultNavigation;

        }
    }

    ngOnInit() {
        

        this.setViewAccessRightsForNavs(this.menulist)
        this.removeMenusWithNoChild(this.menulist)
    }

    getMenuAccess(PermissionCode, PermissionIndex) {
        if (!PermissionCode) return true;
        var accessRights = JSON.parse(localStorage.getItem("accessRights"));

        if (accessRights[PermissionCode] && accessRights[PermissionCode] != '' && accessRights[PermissionCode] != null) {
            var PermissionArray = accessRights[PermissionCode].split(",");

            if (PermissionArray[PermissionIndex] && PermissionArray[PermissionIndex] == 1) {
                // console.log("true", PermissionCode);

                return true;

            } else {
                // console.log("False", PermissionCode);

                return false;
            }
        }
        return false;
    }

    removeMenusWithNoChild(defaultRoutes: NavigationItem[]) {
        defaultRoutes.forEach((route) => {
            if (route.children.filter(a => a.visible).length == 0 && !route.link) {
                route.visible = false;
            }
             if (route.children.filter(a => a.visible).length == 0 && !route.link) {
                route.visible = false;
            }
            if (route.children.filter(a => a.visible).length == 0 && route.link == '#' ) {
                route.visible = false;
            }
            if (route.children.filter(a => a.visible).length != 0 ) {
                this.removeMenusWithNoChild(route.children.filter(a => a.visible));
            }
        });

        // this.menulist = defaultRoutes




    }

    setViewAccessRightsForNavs(menulist) {
        if (menulist)
            menulist.forEach(navI => {

                if (this.getMenuAccess(navI.code, 0)) {
                    navI.visible = true;
                } else {
                    navI.visible = false;
                }
                if (navI.children.length > 0) {
                    this.setViewAccessRightsForNavs(navI.children)
                }


            })
        // this.menulist = menulist
    }






    getMenuList() {
        // this.menulist = [

        //     {
        //         id: 'starter.Dashboard',
        //         name: 'Hotel Management',
        //         subtitle: '',
        //         icon: 'remixicon-hotel-bed-line',
        //         link: 'hotel-management',
        //         children: [

        //         ]
        //     },

        // ];
        // if (window.location.pathname.includes("hotels")) {

        //     this.menulist = [
        //         /* { name: 'Home Page', link: '/hotels', icon: 'remixicon-home-2-line', children: [] }, */
        //         {
        //             name: 'Dashboard', link: '#', icon: 'remixicon-dashboard-line', slug_name: 'dashboard', 
        //             children: [

        //                 {
        //                     id: '"Dashboard"',
        //                     code: 'MyDashboard',
        //                     name: 'My Dashboard',
        //                     icon: 'fa fa-star float-right text-primar',
        //                     link: '/hotels/my-dashboard',
        //                     children: []
        //                 },
        //                 // {
        //                 //     name: 'Reports',
        //                 //     link: '/hotels/reports',
        //                 //     icon: 'fa fa-star float-right text-primary'
        //                 // },

        //                 // {
        //                 //     name: 'Reports V2',
        //                 //     link: '/hotels/charts',
        //                 //     icon: 'fa fa-star float-right text-primary'
        //                 // },

        //                 //{ name: 'Edit My Dashboard', link: '/hotels/edit-my-dashboard' },
        //                 // {name: 'Default Dashboard', link: '/hotels/dashboard'}
        //             ]
        //         },
        //         // {
        //         //     name: 'Twilio Chat', link: '/hotels/twilio-chat', icon: 'remixicon-chat-1-fill', slug_name: 'pages', children: [
        //         //     ]
        //         // },
        //         // {
        //         //     name: 'Reports',
        //         //     link: '/hotels/report',
        //         //     slug_name: 'report', icon: 'fa fa-file',
        //         //     children: []
        //         // },

        //         {
        //             name: 'Reports',
        //             link: '#',
        //             slug_name: 'report', icon: 'fa fa-file',
        //             children: [
        //                 {
        //                     id: 'Reports',
        //                     code: 'NextDayArrival',
        //                     name: 'Next Day Arrival',
        //                     icon: '',
        //                     link: '/hotels/next-day-arrival-report',
        //                     children: []
        //                 },

        //                 {
        //                     id: 'Reports',
        //                     code: 'NextDayArrivalbyGuestType',
        //                     name: 'NextDay Arrival by GuestType',
        //                     icon: '',
        //                     link: '/hotels/guest-type-report',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Reports',
        //                     code: 'NextDayArrivalbyNationality',
        //                     name: 'Next Day Arrival by Nationality',
        //                     icon: '',
        //                     link: '/hotels/arrival-next-day-bynationality-report',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Reports',
        //                     code: 'NextDayArrivalbyMealPlan',
        //                     name: 'Next Day Arrival by Meal Plan',
        //                     icon: '',
        //                     link: '/hotels/arrival-next-day-bymealplan-report',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Reports',
        //                     code: 'NextDayArrivalbySOB',
        //                     name: 'Next Day Arrival by SOB',
        //                     icon: '',
        //                     link: '/hotels/arrival-next-day-bysob-report',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Reports',
        //                     code: 'ARPGbyNationality',
        //                     name: 'ARPG by Nationality',
        //                     icon: '',
        //                     link: '/hotels/arpg-bynationality-report',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Reports',
        //                     code: 'RevparbySOB',
        //                     name: 'Revpar by SOB',
        //                     icon: '',
        //                     link: '/hotels/revpar-bysob-report',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Reports',
        //                     code: 'TRevpar',
        //                     name: 'TRevpar',
        //                     icon: '',
        //                     link: '/hotels/trevpar-report',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Reports',
        //                     code: 'ARPGbySOB',
        //                     name: 'ARPG by SOB',
        //                     icon: '',
        //                     link: '/hotels/arpg-bysob-report',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Reports',
        //                     code: 'TopSpenders',
        //                     name: 'Top Spenders',
        //                     icon: '',
        //                     link: '/hotels/top-spenders-report',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Reports',
        //                     code: 'LowestSpenders',
        //                     name: 'Lowest Spenders',
        //                     icon: '',
        //                     link: '/hotels/lowest-spenders-report',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Reports',
        //                     code: 'RatingReport',
        //                     name: 'Rating Report',
        //                     icon: '',
        //                     link: '/hotels/rating-report',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Reports',
        //                     code: 'AllReports',
        //                     name: 'All Reports',
        //                     icon: '',
        //                     link: '/hotels/report',
        //                     children: []
        //                 },
        //                 // { name: 'Next Day Arrival', link: '/hotels/next-day-arrival-report' },
        //                 // { name: 'Next Day Arrival by Guest Type', link: '/hotels/guest-type-report' },
        //                 // { name: 'Next Day Arrival by Nationality', link: '/hotels/arrival-next-day-bynationality-report' },
        //                 // { name: 'Next Day Arrival by Meal Plan', link: '/hotels/arrival-next-day-bymealplan-report' },
        //                 // { name: 'Next Day Arrival by SOB', link: '/hotels/arrival-next-day-bysob-report' },
        //                 // { name: 'ARPG by Nationality', link: '/hotels/arpg-bynationality-report' },
        //                 // { name: 'Revpar by SOB', link: '/hotels/revpar-bysob-report' },
        //                 // { name: 'TRevpar', link: '/hotels/trevpar-report' },
        //                 // { name: 'ARPG by SOB', link: '/hotels/arpg-bysob-report' },
        //                 // { name: 'Top Spenders', link: '/hotels/top-spenders-report' },
        //                 // { name: 'Lowest Spenders', link: '/hotels/lowest-spenders-report' },
        //                 // { name: 'Reviews by SOB and Nationality', link: '/hotels/reviewsbysobandnationality' },
        //                 // { name: 'Rating Report', link: '/hotels/rating-report' },
        //                 // { name: 'All Reports', link: '/hotels/report' },


        //             ]
        //         },
        //         {
        //             name: 'Quicklinks',
        //             link: '/hotels/quicklink',
        //             slug_name: 'show-to-all',
        //             icon: 'fas fa-link',
        //             children: []
        //         },
        //         // { name: 'Manage Image Gallery', link: '/hotels/Image-gallery', slug_name: 'pages', icon: 'fas fa-image', children: [] },

        //         {
        //             name: 'Manage Pages', link: '#', slug_name: 'pages', icon: 'remixicon-pages-fill', children: [
        //                 {
        //                     id: 'Manage Pages',
        //                     code: 'Pages',
        //                     name: 'Pages',
        //                     icon: '',
        //                     link: '/hotels/cms',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Manage Pages',
        //                     code: 'ManageBookingsMenu',
        //                     name: 'Manage Bookings Menu',
        //                     icon: '',
        //                     link: '/hotels/manage-booking-module-postion',
        //                     children: []
        //                 },
        //                 // { name: 'Pages', link: '/hotels/cms' },
        //                 // { name: 'Manage Bookings Menu', link: '/hotels/manage-booking-module-postion' },
        //             ]
        //         },
        //         {
        //             name: 'Onboarding', link: '#', icon: 'fa fa-crosshairs', slug_name: 'amenity-booking', children: [
        //                 // { name: 'Manage  Bookings', link: '/hotels/AmenityBooking-Kanban' },
        //                 // { name: 'Booking List', link: '/hotels/AmenityBookingList' },
        //                 // { name: 'Booking Calendar', link: '/hotels/AmenityBooking' },
        //                 {
        //                     id: 'Onboarding',
        //                     code: 'Screens',
        //                     name: 'Screens',
        //                     icon: '',
        //                     link: '/hotels/splash-list',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Onboarding',
        //                     code: 'ScreensLanguages',
        //                     name: 'Screens Languages',
        //                     icon: '',
        //                     link: '/hotels/splash-language-list',
        //                     children: []
        //                 },
        //                 // { name: 'Screens', link: '/hotels/splash-list' },
        //                 // { name: 'Screens Languages', link: '/hotels/splash-language-list' },
        //             ]
        //         },
        //         {
        //             name: 'Restaurant', link: '#', icon: 'fas fa-utensils', slug_name: 'restaurant-booking', children: [
        //                 {
        //                     id: 'Restaurant',
        //                     code: 'ManageBookings',
        //                     name: 'Manage Bookings',
        //                     icon: '',
        //                     link: '/hotels/RestaurantBooking-kanban',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Restaurant',
        //                     code: 'BookingList',
        //                     name: 'Booking List',
        //                     icon: '',
        //                     link: '/hotels/RestaurantBookingList',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Restaurant',
        //                     code: 'Bookingcalendar',
        //                     name: 'Booking calendar',
        //                     icon: '',
        //                     link: '/hotels/RestaurantBooking',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Restaurant',
        //                     code: 'ManageRestaurants',
        //                     name: 'Manage Restaurants',
        //                     icon: '',
        //                     link: '/hotels/RestaurantList',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Restaurant',
        //                     code: 'ManageRestaurantLanguage',
        //                     name: 'Manage Restaurant Language',
        //                     icon: '',
        //                     link: '/hotels/restaurant-language',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Restaurant',
        //                     code: 'FoodMenu',
        //                     name: 'Food Menu',
        //                     icon: '',
        //                     link: '/hotels/food-menu',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Restaurant',
        //                     code: 'FoodMenuLanguages',
        //                     name: 'Food Menu Languages',
        //                     icon: '',
        //                     link: '/hotels/food-name',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Restaurant',
        //                     code: 'FoodCategory',
        //                     name: 'Food Category',
        //                     icon: '',
        //                     link: '/hotels/food-category',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Restaurant',
        //                     code: 'FoodCategoryLanguages',
        //                     name: 'Food Category Languages',
        //                     icon: '',
        //                     link: '/hotels/food-category-language',
        //                     children: []
        //                 },
        //                 // { name: 'Manage Bookings', link: '/hotels/RestaurantBooking-kanban' },
        //                 // { name: 'Booking List', link: '/hotels/RestaurantBookingList' },
        //                 // { name: 'Booking calendar', link: '/hotels/RestaurantBooking' },
        //                 // { name: 'Manage Restaurants', link: '/hotels/RestaurantList' },
        //                 // { name: 'Manage Restaurant Language ', link: '/hotels/restaurant-language' },
        //                 // { name: 'Food Menu', link: '/hotels/food-menu' },
        //                 // { name: 'Food Menu Languages', link: '/hotels/food-name' },
        //                 // { name: 'Food Category', link: '/hotels/food-category' },
        //                 // { name: 'Food Category Languages', link: '/hotels/food-category-language' },

        //             ]
        //         },
        //         {
        //             name: 'Spa', link: '#', icon: 'fas fa-spa', slug_name: 'spa-booking', children: [
        //                 {
        //                     id: 'Spa',
        //                     code: 'ManageBookings',
        //                     name: 'Manage Bookings',
        //                     icon: '',
        //                     link: '/hotels/SpaBooking-Kanban',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Spa',
        //                     code: 'BookingList',
        //                     name: 'Booking List',
        //                     icon: '',
        //                     link: '/hotels/SpaBookingList',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Spa',
        //                     code: 'BookingCalendar',
        //                     name: 'Booking Calendar',
        //                     icon: '',
        //                     link: '/hotels/SpaBooking',
        //                     children: []
        //                 },

        //                 {
        //                     id: 'Spa',
        //                     code: 'ManagePackages',
        //                     name: 'Manage Packages',
        //                     icon: '',
        //                     link: '/hotels/spa-package',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Spa',
        //                     code: 'ManagePackageslanguages',
        //                     name: 'Manage Packages languages',
        //                     icon: '',
        //                     link: '/hotels/spa-package-name',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Spa',
        //                     code: 'ManagePackageCategory',
        //                     name: 'Manage Package Category',
        //                     icon: '',
        //                     link: '/hotels/spa-category-list',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Spa',
        //                     code: 'PackagesCategoryLanguages',
        //                     name: 'Manage Package Category Languages',
        //                     icon: '',
        //                     link: '/hotels/spa-category-cms',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Spa',
        //                     code: 'ManageCabin',
        //                     name: 'Manage Cabin',
        //                     icon: '',
        //                     link: '/hotels/cabin-list',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Spa',
        //                     code: 'ManageCabinLanguages',
        //                     name: 'Manage Cabin Languages',
        //                     icon: '',
        //                     link: '/hotels/cabin-language',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Spa',
        //                     code: 'ManageTherapists',
        //                     name: 'Manage Therapists',
        //                     icon: '',
        //                     link: '/hotels/ListTherapist',
        //                     children: []
        //                 },

        //                 {
        //                     id: 'Spa',
        //                     code: 'TherapistCalendar',
        //                     name: 'Therapist Calendar',
        //                     icon: '',
        //                     link: '/hotels/TherapistAvailability',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Spa',
        //                     code: 'CabinCalendar',
        //                     name: 'Cabin Calendar',
        //                     icon: '',
        //                     link: '/hotels/cabin-calendar',
        //                     children: []
        //                 },
        //                 // { name: 'Manage Bookings', link: '/hotels/SpaBooking-Kanban' },
        //                 // { name: 'Booking List', link: '/hotels/SpaBookingList' },
        //                 // { name: 'Booking Calendar', link: '/hotels/SpaBooking' },
        //                 // { name: 'Manage Packages', link: '/hotels/spa-package' },
        //                 // { name: 'Manage Packages languages', link: '/hotels/spa-package-name' },
        //                 // { name: 'Manage Package Category', link: '/hotels/spa-category-list' },
        //                 // { name: 'Packages Category Languages', link: '/hotels/spa-category-cms' },
        //                 // { name: 'Manage Cabin', link: '/hotels/cabin-list' },
        //                 // { name: 'Manage Cabin Languages', link: '/hotels/cabin-language' },
        //                 // { name: 'Manage Therapists', link: '/hotels/ListTherapist' },
        //                 // { name: 'Therapist Calendar', link: '/hotels/TherapistAvailability' },
        //                 // { name: 'Cabin Calendar', link: '/hotels/cabin-calendar' },
        //             ]
        //         },

        //         {
        //             name: 'Bike', link: '#', icon: 'fa fa-motorcycle', slug_name: 'bike-booking', children: [
        //                 {
        //                     id: 'Bike',
        //                     code: 'ManageBookings',
        //                     name: 'Manage Bookings',
        //                     icon: '',
        //                     link: '/hotels/BikeBooking-kanban',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Bike',
        //                     code: 'BookingList',
        //                     name: 'Booking List',
        //                     icon: '',
        //                     link: '/hotels/BookingBikeList',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Bike',
        //                     code: 'BookingsCalendar',
        //                     name: 'Bookings Calendar',
        //                     icon: '',
        //                     link: '/hotels/BikeBooking',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Bike',
        //                     code: 'ManageBike',
        //                     name: 'Manage Bike',
        //                     icon: '',
        //                     link: '/hotels/ListBike',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Bike',
        //                     code: 'ManageBikeLanguage',
        //                     name: 'Manage Bike Language',
        //                     icon: '',
        //                     link: '/hotels/Bike-name',
        //                     children: []
        //                 },
        //                 // { name: 'Manage Bookings', link: '/hotels/BikeBooking-kanban' },
        //                 // { name: 'Booking List', link: '/hotels/BookingBikeList' },
        //                 // { name: 'Bookings Calendar', link: '/hotels/BikeBooking' },
        //                 // { name: 'Manage Bike', link: '/hotels/ListBike' },
        //                 // { name: 'Manage Bike Language ', link: '/hotels/Bike-name' },


        //             ]
        //         },
        //         {
        //             name: 'Boat House', link: '#', icon: 'fas fa-ship', slug_name: 'boat-booking', children: [
        //                 {
        //                     id: 'Boat House',
        //                     code: 'ManageBookings',
        //                     name: 'Manage Bookings ',
        //                     icon: '',
        //                     link: '/hotels/BoatBooking-kanban',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Boat House',
        //                     code: 'BookingList',
        //                     name: 'Booking List ',
        //                     icon: '',
        //                     link: '/hotels/BoatbookingList',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Boat House',
        //                     code: 'BookingsCalendar',
        //                     name: 'Bookings Calendar ',
        //                     icon: '',
        //                     link: '/hotels/BoatBooking',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Boat House',
        //                     code: 'ManageActivity',
        //                     name: 'Manage Activity ',
        //                     icon: '',
        //                     link: '/hotels/activity',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Boat House',
        //                     code: 'ManageBoat',
        //                     name: 'Manage Boat ',
        //                     icon: '',
        //                     link: '/hotels/ListBoats',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Boat House',
        //                     code: 'ManageBoatLanguage',
        //                     name: 'Manage Boat Language ',
        //                     icon: '',
        //                     link: '/hotels/Boat-name',
        //                     children: []
        //                 },
        //                 // { name: 'Manage Bookings', link: '/hotels/BoatBooking-kanban' },
        //                 // { name: 'Booking List', link: '/hotels/BoatbookingList' },
        //                 // { name: 'Bookings Calendar', link: '/hotels/BoatBooking' },
        //                 // { name: 'Manage Activity', link: '/hotels/activity' },
        //                 // { name: 'Manage Boat', link: '/hotels/ListBoats' },
        //                 // { name: 'Manage Boat Language', link: '/hotels/Boat-name' },
        //             ]
        //         },

        //         {
        //             name: 'Room Service', link: '#', icon: 'fa fa-bed', slug_name: 'room-service-booking', children: [
        //                 {
        //                     id: 'Room Service',
        //                     code: 'ManageBookings',
        //                     name: 'Manage Bookings ',
        //                     icon: '',
        //                     link: '/hotels/Roombooking-kanban',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Room Service',
        //                     code: 'BookingList',
        //                     name: 'Booking List ',
        //                     icon: '',
        //                     link: '/hotels/RoomServiceBookingList',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Room Service',
        //                     code: 'ServiceAvailability',
        //                     name: 'Service Availability ',
        //                     icon: '',
        //                     link: '/hotels/service-availability',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Room Service',
        //                     code: 'ServiceAvailabilityLanguage',
        //                     name: 'Service Availability Language ',
        //                     icon: '',
        //                     link: '/hotels/Service-availablity-language',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Room Service',
        //                     code: 'BookingCalendar',
        //                     name: 'Booking Calendar ',
        //                     icon: '',
        //                     link: '/hotels/Roombooking',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Room Service',
        //                     code: 'ManageItemsList',
        //                     name: 'Manage Items List ',
        //                     icon: '',
        //                     link: '/hotels/item-name',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Room Service',
        //                     code: 'ManageServiceDetails',
        //                     name: 'Manage Service Details ',
        //                     icon: '',
        //                     link: '/hotels/room-service',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Room Service',
        //                     code: 'BookingHistoryImage',
        //                     name: 'Booking History Image ',
        //                     icon: '',
        //                     link: '/hotels/booking-history-image',
        //                     children: []
        //                 },
        //                 // { name: 'Service Availability Language', link: '/hotels/Service-availablity-language' },
        //                 // { name: 'Manage Bookings', link: '/hotels/Roombooking-kanban' },
        //                 // { name: 'Booking List', link: '/hotels/RoomServiceBookingList' },
        //                 // { name: 'Service Availability', link: '/hotels/service-availability' },
        //                 // { name: 'Booking Calendar', link: '/hotels/Roombooking' },
        //                 // { name: 'Manage Items List', link: '/hotels/item-name' },
        //                 // { name: 'Manage Service Details', link: '/hotels/room-service' },
        //                 //  { name: 'Booking History Image', link: '/hotels/booking-history-image' },

        //                 // { name: 'Module Settings', link: '/hotels/module-activity' },
        //                 // { name: 'Manage Hoilday', link: '/hotels/hoilday-list' },
        //                 // { name: 'Manage Date Removing', link: '/hotels/datewise-removing' },
        //                 // { name: 'Booking Date Timing settings', link: '/hotels/booking-time-list' },
        //             ]
        //         },
        //         {
        //             name: 'Amenities', link: '#', icon: 'fas fa-building', slug_name: 'amenity-booking', children: [
        //                 {
        //                     id: 'Amenities',
        //                     code: 'ManageBookings',
        //                     name: 'Manage Bookings  ',
        //                     icon: '',
        //                     link: '/hotels/AmenityBooking-Kanban',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Amenities',
        //                     code: 'BookingList',
        //                     name: 'Booking List  ',
        //                     icon: '',
        //                     link: '/hotels/AmenityBookingList',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Amenities',
        //                     code: 'BookingCalendar',
        //                     name: 'Booking Calendar  ',
        //                     icon: '',
        //                     link: '/hotels/AmenityBooking',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Amenities',
        //                     code: 'ManageItemNames',
        //                     name: 'Manage Item Names',
        //                     icon: '',
        //                     link: '/hotels/ListAmenity',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Amenities',
        //                     code: 'ManageItemlanguage',
        //                     name: 'Manage Item language',
        //                     icon: '',
        //                     link: '/hotels/CmsAmenity',
        //                     children: []
        //                 },
        //                 // { name: 'Manage Bookings', link: '/hotels/AmenityBooking-Kanban' },
        //                 // { name: 'Booking List', link: '/hotels/AmenityBookingList' },
        //                 // { name: 'Booking Calendar', link: '/hotels/AmenityBooking' },
        //                 // { name: 'Manage Item Names', link: '/hotels/ListAmenity' },
        //                 // { name: 'Manage Item language', link: '/hotels/CmsAmenity' },
        //             ]
        //         },
        //         // {
        //         //     name: 'Module', link: '#', icon: 'remixicon-settings-2-fill', slug_name: 'room-service-booking', children: [


        //         //         // { name: 'Phone Directory', link: '/hotels/phone-directory-master' },


        //         //     ]
        //         // },
        //         {
        //             name: 'E-Concierge', link: '#', icon: 'remixicon-settings-2-fill', slug_name: 'room-service-booking', children: [

        //                 // { name: 'Phone Dirctory Master', link: 'phone-directory-master' },
        //                 {
        //                     id: 'E-Concierge',
        //                     code: 'Service',
        //                     name: 'Service',
        //                     icon: '',
        //                     link: '/hotels/other-service-details',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'E-Concierge',
        //                     code: 'ServiceLanguages',
        //                     name: 'Service Languages',
        //                     icon: '',
        //                     link: '/hotels/other-service-language',
        //                     children: []
        //                 },
        //                 // { name: 'Service', link: '/hotels/other-service-details' },
        //                 // { name: 'Service Languages', link: '/hotels/other-service-language' },

        //             ]
        //         },
        //         {
        //             name: 'Master', link: '#', icon: 'remixicon-settings-2-fill', slug_name: 'Master', children: [
        //                 // { name: 'Phone Dirctory Master', link: 'phone-directory-master' },


        //             ]
        //         },
        //         {
        //             name: 'Loyalty Programme', link: '#', icon: 'fas fa-laptop-code', slug_name: 'amenity-booking', children: [
        //                 {
        //                     id: 'Loyalty Programme',
        //                     code: 'Transaction',
        //                     name: 'Transaction ',
        //                     icon: '',
        //                     link: '/hotels/customer-point-list',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Loyalty Programme',
        //                     code: 'RewardPoints',
        //                     name: 'Reward Points ',
        //                     icon: '',
        //                     link: '/hotels/customer-total-point-list',
        //                     children: []
        //                 },

        //                 {
        //                     id: 'Loyalty Programme',
        //                     code: 'ServiTier-listceLanguages',
        //                     name: 'Tier-list ',
        //                     icon: '',
        //                     link: '/hotels/Tier-list',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Loyalty Programme',
        //                     code: 'FAQ',
        //                     name: 'FAQ ',
        //                     icon: '',
        //                     link: '/hotels/faq-list',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Loyalty Programme',
        //                     code: 'FAQLanguage',
        //                     name: 'FAQ Language',
        //                     icon: '',
        //                     link: '/hotels/add-language',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Loyalty Programme',
        //                     code: 'TierUpgradeHistory',
        //                     name: 'Tier Upgrade History ',
        //                     icon: '',
        //                     link: '/hotels/transaction-history',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Loyalty Programme',
        //                     code: 'ClaimMissingPoints',
        //                     name: 'Claim Missing Points',
        //                     icon: '',
        //                     link: '/hotels/claim-missing-points',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Loyalty Programme',
        //                     code: 'PointsSettings',
        //                     name: 'Points Settings ',
        //                     icon: '',
        //                     link: '/hotels/point-setting-list',
        //                     children: []
        //                 },
        //                 // { name: 'Transaction', link: '/hotels/customer-point-list' },
        //                 // { name: 'Reward Points', link: '/hotels/customer-total-point-list' },
        //                 // { name: 'Tier-list', link: '/hotels/Tier-list' },
        //                 // { name: 'Tier Language', link: '/hotels/tier-language' },
        //                 // { name: 'FAQ', link: '/hotels/faq-list' },
        //                 // { name: 'FAQ Language', link: '/hotels/add-language' },
        //                 // { name: 'Tier Upgrade History', link: '/hotels/transaction-history' },
        //                 // { name: 'Claim Missing Points', link: '/hotels/claim-missing-points' },
        //                 // { name: 'Points Settings', link: '/hotels/point-setting-list' },

        //             ]
        //         },

        //         {
        //             name: 'Check In', link: '#', icon: 'fa fa-check', slug_name: 'check-in', children: [
        //                 {
        //                     id: 'Check In',
        //                     code: 'ManageCheckIn',
        //                     name: 'Manage Check In',
        //                     icon: '',
        //                     link: '/hotels/CheckInList',
        //                     children: []
        //                 },
        //                 // { name: 'Manage Check In', link: '/hotels/CheckInList' },
        //             ]
        //         },
        //         { name: 'Customers', link: '/hotels/customers', slug_name: 'pages', icon: 'fa fa-users', children: [] },
        //         // {
        //         //     name: 'Place of Interest', link: '#', slug_name: 'pages', icon: 'fas fa-place-of-worship', children: [
        //         //         { name: 'Place of Interest Languages', link: '/hotels/area-of-interest-cms' },
        //         //         { name: 'Manage Place of Interest', link: '/hotels/area-of-interest' },

        //         //     ]
        //         // },
        //         {
        //             name: 'SLA', link: '#', icon: 'fas fa-clock', slug_name: 'check-in', children: [
        //                 {
        //                     id: 'SLA',
        //                     code: 'SLAList',
        //                     name: 'SLA List',
        //                     icon: '',
        //                     link: '/hotels/SLAList',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'SLA',
        //                     code: 'ManageSLA',
        //                     name: 'Manage SLA',
        //                     icon: '',
        //                     link: '/hotels/ManageSla',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'SLA',
        //                     code: 'SLAEmail',
        //                     name: 'SLA Email',
        //                     icon: '',
        //                     link: '/hotels/sla-email',
        //                     children: []
        //                 },
        //                 // {
        //                 //     id: 'ManageNotification',
        //                 //     code: 'ManageNotification',
        //                 //     name: 'Manage Notification',
        //                 //     icon: '',
        //                 //     link: '/hotels/manage-notification',
        //                 //     children: []
        //                 // },
        //                 //  {name:'Manage Notification', link:'/hotels/manage-notification'},
        //                 // { name: 'SLA list', link: '/hotels/SLAList' },
        //                 // { name: 'Manage SLA', link: '/hotels/ManageSla' },
        //                 // { name: 'SLA Email', link: '/hotels/sla-email' },

        //             ]
        //         },
        //         // { name: 'Module', link: '/hotels/module-activity', slug_name: 'module-activity', icon: 'fas fa-image', children: [] },

        //         {
        //             name: 'Events', link: '#', icon: 'remixicon-calendar-2-fill', slug_name: 'events', children: [
        //                 {
        //                     id: 'Events',
        //                     code: 'EventsPlanning',
        //                     name: 'Events Planning',
        //                     icon: '',
        //                     link: '/hotels/ListEvent',
        //                     children: []
        //                 },
        //                 // { name: 'Events Planning', link: '/hotels/ListEvent' },

        //             ]
        //         },

        //         {
        //             name: 'Reviews & Ratings',
        //             link: '#',
        //             icon: 'fa fa-thumbs-up',
        //             slug_name: 'reviews-ratings',
        //             children: [
        //                 {
        //                     id: 'Reviews & Ratings',
        //                     code: 'ReviewsList',
        //                     name: 'Reviews List',
        //                     icon: '',
        //                     link: '/hotels/ReviewsList',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Reviews & Ratings',
        //                     code: 'ActionList',
        //                     name: 'Action List',
        //                     icon: '',
        //                     link: '/hotels/TasksList',
        //                     children: []
        //                 },

        //                 {
        //                     id: 'Reviews & Ratings',
        //                     code: 'PoorReviewList',
        //                     name: 'Poor Review List',
        //                     icon: '',
        //                     link: '/hotels/poor-reviews-list',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Reviews & Ratings',
        //                     code: 'QuestionsList',
        //                     name: 'Questions List',
        //                     icon: '',
        //                     link: '/hotels/ManageQuestion',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Reviews & Ratings',
        //                     code: 'QuestionsMapping',
        //                     name: 'Questions Mapping',
        //                     icon: '',
        //                     link: '/hotels/QuestionList',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'Reviews & Ratings',
        //                     code: 'QuestionsLanguage',
        //                     name: 'Questions Language',
        //                     icon: '',
        //                     link: '/hotels/CmsRatingQuestion',
        //                     children: []
        //                 },
        //                 // { name: 'Reviews List', link: '/hotels/ReviewsList' },
        //                 // { name: 'Action List', link: '/hotels/TasksList' },
        //                 // { name: 'Questions List', link: '/hotels/ManageQuestion' },
        //                 // { name: 'Questions Mapping', link: '/hotels/QuestionList' },
        //                 // { name: 'Questions Language', link: '/hotels/CmsRatingQuestion' },
        //             ]
        //         },
        //         {
        //             name: 'User Management',
        //             link: '#',
        //             icon: 'remixicon-settings-2-fill',
        //             slug_name: 'user-management',
        //             children: [
        //                 {
        //                     id: 'User Management',
        //                     code: 'UserList',
        //                     name: 'User List',
        //                     icon: '',
        //                     link: '/hotels/AdminList',
        //                     children: []
        //                 },
        //                 {
        //                     id: 'User Management',
        //                     code: 'RoleList',
        //                     name: 'Role List',
        //                     icon: '',
        //                     link: '/hotels/RoleList',
        //                     children: []
        //                 },
        //                 // { name: 'User List', link: '/hotels/AdminList' },
        //                 // { name: 'Role List', link: '/hotels/RoleList' },
        //             ]
        //         },
        //         // {
        //         //     name: 'Beacons', link: '#', icon: 'remixicon-broadcast-fill', slug_name: 'beacons', children: [
        //         //         { name: 'Geo Location', link: '/hotels/ListGeoLocation' },
        //         //         { name: 'Beacon Message', link: '/hotels/ListBeaconMessage' },
        //         //     ]
        //         // },

        //         // { name: 'Reports', link: '/hotels/reports', slug_name: 'pages', icon: 'fa fa-file', children: [] },
        //         {
        //             name: 'Settings', link: '#', icon: 'remixicon-broadcast-fill', slug_name: 'beacons', children: [
        //                 //  name: 'General', link: '/hotels/settings', slug_name: 'pages', icon: 'remixicon-settings-3-line', children: [
        //                     {
        //                         id: 'Settings',
        //                         code: 'CMSPortalSettings',
        //                         name: 'CMS Portal Settings',
        //                         icon: '',
        //                         link: '/hotels/settings',
        //                         children: []
        //                     },
        //                     {
        //                         id: 'Settings',
        //                         code: 'MobileAppSettings',
        //                         name: 'Mobile App Settings',
        //                         icon: '',
        //                         link: '/hotels/mobile-app-settings',
        //                         children: []
        //                     },
        //                     {
        //                         id: 'Settings',
        //                         code: 'BookingSettings',
        //                         name: 'Booking Settings',
        //                         icon: '',
        //                         link: '/hotels/module-activity',
        //                         children: []
        //                     },
        //                     {
        //                         id: 'Settings',
        //                         code: 'TimeSlots',
        //                         name: 'Time Slots',
        //                         icon: '',
        //                         link: '/hotels/booking-time-list',
        //                         children: []
        //                     },
        //                     {
        //                         id: 'Settings',
        //                         code: 'TimingExceptions',
        //                         name: 'Timing Exceptions',
        //                         icon: '',
        //                         link: '/hotels/datewise-removing',
        //                         children: []
        //                     },
        //                     {
        //                         id: 'Settings',
        //                         code: 'TimingOverride',
        //                         name: 'Timing Override',
        //                         icon: '',
        //                         link: '/hotels/special-timimg-list',
        //                         children: []
        //                     },
        //                     {
        //                         id: 'Settings',
        //                         code: 'ManageHolidays',
        //                         name: 'Manage Holidays',
        //                         icon: '',
        //                         link: '/hotels/hoilday-list',
        //                         children: []
        //                     },
        //                 // { name: 'CMS Portal Settings', link: '/hotels/settings' },
        //                 // { name: 'Mobile App Settings', link: '/hotels/mobile-app-settings' },
        //                 // { name: 'Booking Settings', link: '/hotels/module-activity' },
        //                 // { name: 'Time Slots', link: '/hotels/booking-time-list' },
        //                 // { name: 'Timing Exceptions', link: '/hotels/datewise-removing' },
        //                 // { name: 'Timing Override', link: '/hotels/special-timimg-list' },
        //                 // { name: 'Manage Holidays', link: '/hotels/hoilday-list' },
        //             ]
        //         },

        //     ];
        //     let permissions = JSON.parse(localStorage.getItem("PermissionList"));
        //     this.menulist = this.menulist.filter(function (value) {
        //         return permissions.includes(value.slug_name);
        //     }, permissions);
        // }

    }

    ngAfterViewInit() {
        this.menu = new MetisMenu(this.sideMenu.nativeElement);

        this._activateMenuDropdown();
    }

    ngOnChanges() {
        if (!this.isCondensed && this.sideMenu || this.isCondensed) {
            setTimeout(() => {
                this.menu = new MetisMenu(this.sideMenu.nativeElement);
            });
        }
        else if (this.menu) {
            this.menu.dispose();
        }
    }


    getClass(path) {
        return (window.location.pathname.includes(path)) ? 'active' : ''
    }
    /**
     * small sidebar
     */
    smallSidebar() {
        document.body.classList.add('left-side-menu-sm');
        document.body.classList.remove('left-side-menu-dark');
        document.body.classList.remove('topbar-light');
    }

    /**
     * Dark sidebar
     */
    darkSidebar() {
        document.body.classList.remove('left-side-menu-sm');
        document.body.classList.toggle('left-side-menu-dark');
    }

    /**
     * Light Topbar
     */
    lightTopbar() {
        document.body.classList.add('left-side-menu-dark');
        document.body.classList.add('topbar-light');
        document.body.classList.remove('left-side-menu-sm');
        document.body.classList.remove('enlarged');

    }

    /**
     * Sidebar collapsed
     */
    sidebarCollapsed() {
        document.body.classList.add('enlarged');
        document.body.classList.remove('left-side-menu-dark');
        document.body.classList.remove('left-side-menu-sm');
        document.body.classList.remove('boxed-layout');

    }

    /**
     * Boxed Layout
     */
    boxedLayout() {
        document.body.classList.toggle('boxed-layout');
        document.body.classList.remove('left-side-menu-dark');
        document.body.classList.add('enlarged');
    }

    /**
     * Activates the menu dropdown
     */
    _activateMenuDropdown() {
        const links = document.getElementsByClassName('side-nav-link-ref');
        let menuItemEl = null;
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < links.length; i++) {
            // tslint:disable-next-line: no-string-literal
            if (window.location.pathname === links[i]['pathname']) {
                menuItemEl = links[i];
                break;
            }
        }

        if (menuItemEl) {
            menuItemEl.classList.add('active');

            const parentEl = menuItemEl.parentElement;
            if (parentEl) {
                parentEl.classList.add('active');

                const parent2El = parentEl.parentElement;
                if (parent2El) {
                    parent2El.classList.add('in');
                }

                const parent3El = parent2El.parentElement;
                if (parent3El) {
                    parent3El.classList.add('mm-active');
                    parent3El.firstChild.classList.add('mm-active');
                }
                // const parent4El = parent3El.parentElement;
                // if (parent4El) {
                //     menuItemEl.classList.remove('mm-active');
                //     // parent3El.classList.add('mm-active');
                //     // parent3El.firstChild.classList.add('mm-active');
                // }
            }
        }
    }

}
