export {  quicklinks };

const quicklinks = [
    { name: 'Restaurant', link: '/hotels/RestaurantBooking-kanban', 'slug_name' : 'restaurant-booking', image: 'assets/images/icons/Food.png' },
    { name: 'SPA', link: '/hotels/SpaBooking-Kanban', 'slug_name' : 'spa-booking', image: 'assets/images/icons/Spa.png' },
    { name: 'Bike', link: '/hotels/BikeBooking-kanban', 'slug_name' : 'bike-booking', image: 'assets/images/icons/Bike.png' },
    { name: 'Boat', link: '/hotels/BoatBooking-kanban', 'slug_name' : 'boat-booking', image: 'assets/images/icons/Boat.png' },
    { name: 'Room', link: '/hotels/Roombooking-kanban', 'slug_name' : 'room-service-booking\n', image: 'assets/images/icons/Rooms.png' },
    // { name: 'Activities', link: '#', 'slug_name' : '', image: 'assets/images/icons/Activities.png' },
    { name: 'Amenities', link: '/hotels/AmenityBooking-Kanban', 'slug_name' : 'amenity-booking', image: 'assets/images/icons/Amenities.png' },
    // { name: 'Drinks', link: '#', 'slug_name' : '', image: 'assets/images/icons/Drinks.png' },
    // { name: 'Outing/Taxi', link: '#', 'slug_name' : '', image: 'assets/images/icons/Taxi.png' },
    //{ name: 'Pages', link: '/hotels/pages', 'slug_name' : '', image: 'assets/images/icons/Pages.png' },
    
    //{ name: 'Maps', link: '/hotels/ListMap', 'slug_name' : 'maps', image: 'assets/images/icons/UploadMaps.png' },
    { name: 'Policy', link: '/hotels/policy', 'slug_name' : 'policy', image: 'assets/images/icons/Policy.png' },
   // { name: 'Weather', link: '/hotels/weather', 'slug_name' : 'weather', image: 'assets/images/icons/Weather.png' },
   // { name: 'Service Tabber', link: '/hotels/service-tabber', 'slug_name' : 'service-tabber', image: 'assets/images/icons/ServiceTabbar.png' },
    { name: 'Mobile User', link: '/hotels/mobile-user', 'slug_name' : 'mobile-user', image: 'assets/images/icons/MobileUsers.png' },
    { name: 'Phone Directory', link: '/hotels/phone-directory-master', 'slug_name' : 'phone-directory', image: 'assets/images/icons/PhoneDirectory.png' },
    { name: 'Phone Directory Languages', link: '/hotels/phone-directory', 'slug_name' : 'phone-directory', image: 'assets/images/icons/PhoneDirectory.png' },
   // { name: 'Access Rights', link: '/hotels/ListAccessRights', 'slug_name' : 'access-rights', image: 'assets/images/icons/AccessRights.png' },
    // { name: 'Booking Messages', link: '/hotels/booking-messages', 'slug_name' : 'booking-messages', image: 'assets/images/icons/BookingMessages.png' },
   //{ name: 'Disclaimer', link: '/hotels/disclaimer_list', 'slug_name' : 'disclaimer', image: 'assets/images/icons/Disclaimer.png' },
    { name: 'Message', link: '/hotels/ListMessage', 'slug_name' : 'message', image: 'assets/images/icons/Messages.png' },
    { name: 'Languages', link: '/hotels/ListLanguageMaster', 'slug_name' : 'languages', image: 'assets/images/icons/Languages.png' },
    // { name: 'Manage Image Gallery', link: '/hotels/Image-gallery', 'slug_name' : 'banners', image: 'assets/images/icons/Banner.png' },
   // { name: 'Watch TV', link: '/hotels/tv-channel', 'slug_name' : 'watch-tv', image: 'assets/images/icons/WatchTV.png' },

    { name: 'Events', link: '/hotels/ListEvent', 'slug_name' : 'events', image: 'assets/images/icons/Events.png' },
    //{ name: 'Module', link: '/hotels/module-activity', 'slug_name' : 'module-activity', image: 'assets/images/icons/CreateUser.png' },
   // { name: 'User', link: '/hotels/user', 'slug_name' : 'user-management', image: 'assets/images/icons/CreateUser.png' },
    // { name: 'Campaigns', link: '#', 'slug_name' : '', image: 'assets/images/icons/Campaigns.png' },
    // { name: 'CSV Extract', link: '#', 'slug_name' : '', image: 'assets/images/icons/CSVExtract.png' },
    // { name: 'Pop-Ups', link: '#', 'slug_name' : '', image: 'assets/images/icons/PopUps.png' },
];