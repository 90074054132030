import { environment } from "src/environments/environment";

const AppConfig = {
    // app_name: 'Sun Resorts',
    // image_base_path : 'assets/images/application/sun-resorts',
   // currencyCode: '(Rs.)',
    currencyCode: '',
    // app_name : 'Heritage',
    // image_base_path : 'assets/images/application/heritage',
    // 'app_name' : 'Preskil',
    // 'image_base_path' : 'assets/images/application/preskil',
    // app_name : 'Southern Cross',
    // image_base_path : 'assets/images/application/southern_cross',
    app_name: 'Screenage',
    image_base_path: 'assets/images/application/screenage',
    // app_name : 'BEACHCOMBER',
    // image_base_path : 'assets/images/application/beachcomber',


    //  app_name : 'WYNTRONIX',
    //  image_base_path : 'assets/images/application/wyntronix',

    // project_url:'http://sun.smartpoint.in',
    project_url: environment.api.apiURLForImage,

    // project_url:'http://preskil.fortiddns.com:8021'

    //project_url:'http://wyntronix.smartpoint.in'
};

export { AppConfig };
