// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
var groupname=localStorage.getItem('GroupName').toLowerCase();

if(groupname=="Mercury"){
	
	var apiURL1 ="//www.cmsmercuryguestapp.com:8021/api/v3";
	var apiURLForToken1="//www.cmsmercuryguestapp.com:8021/api/Token";
	var apiURLForImage1="//www.cmsmercuryguestapp.com:8021";

}
else if(groupname=="Ayla")
{
	
	var apiURL1 ="//www.cmsmercuryguestapp.com:8022/api/v3";
	var apiURLForToken1="//www.cmsmercuryguestapp.com:8022/api/Token";
	var apiURLForImage1="//www.cmsmercuryguestapp.com:8022";
}
else if(groupname=="Screenage")
{ 
	var apiURL1 ="//screenagedevapi.smartpoint.in/api/v3";
	var apiURLForToken1="//screenagedev.smartpoint.in/api/Token";
	var apiURLForImage1="//screenagedev.smartpoint.in";
}
else if(groupname=="Testinggroup")
{ 
	var apiURL1 ="//screenagedevapi.smartpoint.in/api/v3";
	var apiURLForToken1="//screenagedev.smartpoint.in/api/Token";
	var apiURLForImage1="//screenagedev.smartpoint.in";
}
export const environment = {
	production: false,
	isMockEnabled: false, // You have to switch this, when your real back-end is done
	Authorization: 'Base64 YWRtaW46YWRtaW5AMzIx',
	
	api: {
		
		// apiURL: window.location.protocol + "//preskil.fortiddns.com:8021/api/v2"
		// apiURL: window.location.protocol + "//sun.smartpoint.in/api/v3"
		//Live
		//apiURL: window.location.protocol + "//screenagedev.smartpoint.in/api/v3",
		//LIVE tEST api
		//apiURL: window.location.protocol + "//screenagedevapi.smartpoint.in/api/v3",
		//apiURL: window.location.protocol + "//wyntronix.smartpoint.in/api/v2"
		//http://localhost:5962/

		//local
		//apiURL: window.location.protocol + "//localhost:5962/api/v3",
		//apiURLForToken: window.location.protocol + "//localhost:5962/api/Token",
		// Testing

		//live
		// apiURLForToken: window.location.protocol + "//screenagedev.smartpoint.in/api/Token",
		// apiURLForImage: window.location.protocol + "//screenagedev.smartpoint.in"
		//LIVE tEST api
		//apiURLForToken: window.location.protocol + "//screenagedevapi.smartpoint.in/api/Token",
		//apiURLForImage: window.location.protocol + "//screenagedevapi.smartpoint.in"


		//Mercury Client server
		// apiURL: window.location.protocol + "//www.cmsmercuryguestapp.com:8021/api/v3",
		// apiURLForToken: window.location.protocol + "//www.cmsmercuryguestapp.com:8021/api/Token",
		// apiURLForImage: window.location.protocol + "//www.cmsmercuryguestapp.com:8021"


		//Ayla Client server
		// apiURL: window.location.protocol + "//www.cmsmercuryguestapp.com:8022/api/v3",
		// apiURLForToken: window.location.protocol + "//www.cmsmercuryguestapp.com:8022/api/Token",
		// apiURLForImage: window.location.protocol + "//www.cmsmercuryguestapp.com:8022"

		//local common
		apiURL: "https:"  + apiURL1,
		apiURLForToken: "https:"  +apiURLForToken1,
		apiURLForImage: "https:"  + apiURLForImage1

			// common
			// apiURL: apiURL1,
			// apiURLForToken: apiURLForToken1,
			// apiURLForImage: apiURLForImage1

	}
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
