let URI = {
    'getHotelList': '/customerlogin/hotel_list_userid',
    'getHotelLists': '/hotel_Api/hotel_list',

    //Notification:
    'removeNotificationlist': '/admin_dashboard/clear_notification_list',
    'viewNotificationlist': '/admin_dashboard/view_all_list_notification',
    'getNotificationList': '/admin_dashboard/list_notification',
    'clearNotificationByUser': '/admin_dashboard/clear_notification_by_user',
    'getNewBookingNotificationList': '/admin_dashboard/new_booking_notification_list',

    //Reports
    'getGuestTypeStatistics': '/admin_dashboard/GuestTypeStatictic',
    'getNationalityStatistics': '/admin_dashboard/Nationality',
    'getMealPlanStatistics': '/admin_dashboard/ArrivalTomorrowByMealPlan',
    'getGuestTypeStatistics1': '/admin_dashboard/guesttype_statistics',


    //Settings:
    'getSettingView': '/SettingsGeneral_Api/View',
    'SettingUpdate': '/SettingsGeneral_Api/Update',
    'CurrencyList':'/SettingsGeneral_Api/GetCurrencyDropdown',
    'TierUpgradeLevelTypeList':'/tier/TierUpgradeLevelType',
  

    //Mobile Settings
    'MobileSettingUpdate': '/SettingsGeneral_Api/Update_mobile_app_settings',
    //Hotel
    'CreatHotel': '/hotel_Api/create_hotel',
    'UpdateHotel': '/hotel_Api/update_hotel',
    'DeleteHotel': '/hotel_Api/delete_Hotel',
    'ViewHotel': '/hotel_Api/view_hotel',


    // Dashboard
    'getAllwidgetList': '/widgets/widgets_list',
    'updateWidget': '/widgets/update_widgets',
    'updateAllWidgets': '/widgets/update_all_widgets_isactive1',
    'getUserWidgetList': '/userwidgets/user_widgets_list',
    'updateUserWidget': '/userwidgets/update_user_widgets',

    // Dashboard Widgets
    'getLatestReviews': '/admin_dashboard/lastest_reviews',
    'getAverageRatings': '/admin_dashboard/overall_average_ratings',
    'getArrivingList': '/admin_dashboard/today_arriving_list',
    'getDepartingList': '/admin_dashboard/today_departure_list',

    'getTopCountries': '/admin_dashboard/top_country_list',
    'getMobileDesktops': '/admin_dashboard/mobile_desktop_Counts',
    'getCheckinDetails': '/admin_dashboard/total_countof_checkin_details',
    'getRoomOccupancy': '/admin_dashboard/room_occupancy',
    'getSLAStatistics': '/admin_dashboard/sla_statistics',
    'getBookingStatistics': '/admin_dashboard/booking_statistics',
    'getAlertSLA': '/admin_dashboard/top10_slabooking_list',


    // CMS URI
    'getPageList': '/page/tree_viewlist_pagemaster',
    'getContent': '/pagecontent_Api/view_pagecontent_by_pageId_languageid',
    'createContent': '/pagecontent_Api/create_page_content',
    'updateContent': '/pagecontent_Api/update_page_content',
    'createPageInfo': '/page/create_page',
    'updatePageInfo': '/page/edit_page',
    'deletePageInfo': '/page/delete_page',
    'updatePageOrder': '/page/treeview_update_page',
    'BookingMenu':'/page/booing_menu_tree_viewlist_pagemaster',
    'GetMainmenulist':'/page/tree_viewlist_pagemaster_main_menu',
    'Downloadpages':'/page/download_page_list',
    

    'getMainMenu':'/page/tree_viewlist_pagemaster_main_menu',
    'getMainMenunew':'/page/tree_viewlist_pagemaster_main_menu_manage_position',
    'updateMainMenu':'/pages/update_page_order_position_by_pageid',
    'getSubMenu':'/pages/v3_order_sub_menu_list',
    'updateSubMenu':'/pages/update_sub_page_order_position_by_pageid',
    'getModuleListforpage':'/module/moduel_list_booking_menu',
    'createbookingmenu':'/page/create_bookingsmenu',
    'Updatebookingmenu':'/page/update_BookingsMenu',

    // Policy
    'GetPolicyType': '/policy_Api/get_policy_types_list',
    'GetPolicyContent': '/policy_Api/policy_content',
    'createPolicyContent': '/policy_Api/create_policy',
    'updatePolicyContent': '/policy_Api/update_policy',

    // Disclaimer
    'GetDisclaimerType': '/disclaimer/get_disclaimer_types_list',
    'GetDisclaimerContent': '/disclaimer/view_disclaimer_content',
    'CreateDisclaimerContent': '/disclaimer/create_disclaimer',
    'UpdateDisclaimerContent': '/disclaimer/update_disclaimer',

    //Spa
    'getKanbanBoardSpaList': '/SpaBooking_Api/KanbanSpaBooking_list',
    'spaBookingChangeStatus': '/SpaBooking_Api/update_kanbanStatus_SpaBooking',
    'spaBookingRemarksupdate': '/SpaBooking_Api/update_kanbanRemarks_SpaBooking',
    'viewSpaBooking': '/SpaBooking_Api/view_SpaBooking',
    'getStatusList': '/RestaurantBooking/ddlRestaurantBookingStatus_list',
    'getSpaPackageList': '/SpaBooking_Api/ddlSpaPackages_list',
    'getSpaBookingList': '/SpaBooking_Api/SpaBooking_list',
    'addSpaBooking': '/SpaBooking_Api/create_SpaBooking',
    'editSpaBooking': '/SpaBooking_Api/update_SpaBooking',
    'deleteSpaBooking': '/SpaBooking_Api/delete_SpaBooking',
    'getUserList': '/mobile_user/get_mobile_user_list',
    'getTherapistList': '/therapist/therapist_list',
    'getddlTherapistList': '/therapist/ddltherapist_list',
    'GetSPAPackageList': '/spapackage/spa_package_list',
    'CreateSPAPackage': '/spapackage/create_spa_package',
    'EditSPAPackage': '/spapackage/update_spa_package',
    'ViewSPAPackage': '/spapackage/view_spa_package',
    'DeleteSPAPackage': '/spapackage/delete_spa_package',
    'GetCategoriesList': '/spapackage/ddlSpaPackageCategory_list',
    'GetSPALanguageList': '/spapackage/cms_spa_package_language_list',
    'GetSPALanguageContent': '/spapackage/cms_view_spa_package_language',
    'CreateSPALanguageContent': '/spapackage/cms_create_spa_package_language',
    'UpdateSPALanguageContent': '/spapackage/cms_edit_spa_package_language',
    'getAvailableTherapist': '/therapist/available_therapist_list',
    'UpdateTherapist': '/SpaBooking_Api/Update_thrapist_SpaBooking',
    'getAmount': '/SpaBooking/GetAmountBySpaPackageId',
    'getTherapistAvailabilityList': '/therapist/therapist_availability_calenderlist',
    'GetCMSSpaPackageCategoryList': '/spa_packagecategory/CMSList',
    'GetCMSSpaPackageCategoryContent': '/spa_packagecategory/CMSView',
    'CreateCMSSpaPackageCategoryContent': '/spa_packagecategory/CMSCreate',
    'UpdateCMSSpaPackageCategoryContent': '/spa_packagecategory/CMSEdit',
    'getcategoryList': '/spa_packagecategory/spa_packagecategory_list',
    'createCategoryList': '/spa_packagecategory/create_spa_packagecategory',
    'updateCategoryList': '/spa_packagecategory/edit_spa_packagecategory',
    'viewCategoryList': '/spa_packagecategory/view_spa_packagecategory',
    'deleteCategoryList': '/spa_packagecategory/delete_spa_packagecategory',





    //Restaurant
    'getRestaurantList': '/restaurant/restaurant_list',
    'addRestaurant': '/restaurant/create_restaurant',
    'editRestaurant': '/restaurant/edit_restaurant',
    'viewRestaurant': ' `/restaurant/view_restaurant?Restaurant=`+data.RestaurantId',
    'deleteRestaurant': '/restaurant/delete_restaurant',
    'getRestaurantBookingList': '/RestaurantBooking/RestaurantBooking_list',
    'addRestaurantBooking': '/RestaurantBooking/create_RestaurantBooking',
    'editRestaurantBooking': '/RestaurantBooking/update_RestaurantBooking',
    'viewRestaurantBooking': '/RestaurantBooking/view_RestaurantBooking',
    'deleteRestaurantBooking': '/RestaurantBooking/delete_RestaurantBooking',
    'getRestaurantListddl': '/RestaurantBooking/ddlRestaurant_list',
    'StatusList': '/RestaurantBooking/ddlRestaurantBookingStatus_list',
    'getKanbanboardList': '/RestaurantBooking/KanabanRestaurantBooking_list',
    'restaurantBookingChangeStatus': '/RestaurantBooking/update_kanbanStatus_RestaurantBooking',
    'restaurantBookingRemarksupdate': '/RestaurantBooking/update_kanbanRemarks_RestaurantBooking',
    'getRestaurantUserLists': '/mobile_user/in_house_mobile_user_list',
    'getGuestname': '/mobile_user/get_guestname_mobileno_by_userid',
    'getGuestnameByCheckin': '/mobile_user/get_guestname_mobileno_by_checkinid',
    'getPagesList': '/page/page_list',
    'GetRestaurantLanguageList': '/Restaurant/cms_Restaurant_language_list',
    'ViewRestaurantLanguageList': '/Restaurant/cms_view_Restaurant_Content',
    'CreateRestaurantLanguageContent': '/Restaurant/cms_create_Restaurant_Content',
    'UpdateRestaurantLanguageContent': '/Restaurant/cms_update_Restaurant_Content',

    // Econcierge
    'getEconciergeKanbanboardList': '/Econcierge/KanbanEconciergeBooking_list',
    'EconciergeBookingChangeStatus': '/Econcierge/update_kanbanStatus_EconciergeBooking',
    'EconciergeBookingRemarksupdate': '/Econcierge/update_kanbanRemarks_EconciergeBooking',
    'ddlOtherService_list': '/Econcierge/ddlOtherService_list',
    'CreateEconciergeBooking': '/Econcierge/Create_EconciergeBooking',
    'EditEconciergeBooking': '/Econcierge/Update_EconciergeBooking',
    'getEconciergeBookingList': '/Econcierge/List_Econcierge_Booking',
    'viewEconciergeBooking': '/Econcierge/view_Calender_EconciergeBooking',
    'getFetchEconciergeBookingTimeInfo': '/Econcierge/FetchRestaurantBookingTimeInfo',
    'deleteEconciergeBooking': '/Econcierge/Delete_Calender_EconciergeBooking',






    //Bike
    'getBikeList': '/bike/bike_list',
    'addBike': '/bike/create_bike',
    'editBike': '/bike/update_bike',
    'viewBike': '/bike/view_bike',
    'deleteBike': '/bike/delete_Bike',
    'getBikeBookingList': '/BikeBooking/List_Calender_BikeBooking',
    'addBikeBooking': '/BikeBooking/Create_Calender_BikeBooking',
    'editBikeBooking': '/BikeBooking/Update_Calender_BikeBooking',
    'viewBikeBooking': '/BikeBooking/view_Calender_BikeBooking',
    'deleteBikeBooking': '/BikeBooking/Delete_Calender_BikeBooking',
    'getBikeListddl': '/BikeBooking/ddlBike_list',
    'getBikeKanbanboardList': '/BikeBooking/KanbanBikeBooking_list',
    'bikeBookingChangeStatus': '/BikeBooking/update_kanbanStatus_BikeBooking',
    'bikeBookingRemarksupdate': '/BikeBooking/update_kanbanRemarks_BikeBooking',
    'GetBikeLanguageList': '/bike/cms_bike_language_list',
    'ViewBikeLanguageList': '/bike/cms_view_bike_Content',
    'CreateBikeLanguageContent': '/bike/cms_create_bike_Content',
    'UpdateBikeLanguageContent': '/bike/cms_update_bike_Content',
    'getPricebyBikeId': '/BikeBooking/BikePriceByBikeId',
    



    //Reviews & Ratings
    'getRatingQuestionsList': '/ratingquestions/rating_questions_list',
    'createRatingQuestions': '/ratingquestions/create_rating_questions',
    'updateRatingQuestions': '/ratingquestions/update_rating_question',
    'deleteRatingQuestions': '/ratingquestions/delete_rating_question',
    'viewRatingQuestions': '/ratingquestions/view_rating_question',
    'viewManageQuestions': '/ratingquestions/view_manage_question',
    'updateManageQuestions': '/ratingquestions/update_manage_question',
    'getModuleList': '/module/moduel_list',
    'getModuleListforloyalty':'/module/moduel_list_for_loyalty',
    'viewManageQuestion': '/ratingquestions/view_manage_question',
    'updateManageQuestion': '/ratingquestions/update_manage_question',
    'getCmsRatingQueList': '/ratingquestions/cms_rating_question_language_list',
    'addCmsRatingQue': '/ratingquestions/cms_create_rating_question_language',
    'viewCmsRatingQue': '/ratingquestions/cms_view_rating_question_language',
    'updateCmsRatingQue': '/ratingquestions/cms_edit_rating_questions_language',
    'getReviewsList': '/admin_dashboard/user_review_rating_list',
    'viewRating': '/user_review/view_user_review_rating',
    'deleteRating': '/user_review/delete_user_review_rating',



    //Task
    'getTaskList': '/admin_dashboard/task_review_rating_list',
    'getAdminList': '/user/admin_user_list?HotelId=',
    'updateTaskAssignTo': '/task_rating/update_task_assingto',
    'deleteTask': '/task_rating/delete_task_rating',
    'getStatus': '/task_rating/task_status_list',
    'updateTask': '/task_rating/update_task',
    'viewTask': '/task_rating/view_task_rating',

    //Boat
    'getBoatList': '/boat/boat_list',
    'addBoat': '/boat/create_boat',
    'updateBoat': '/boat/update_boat',
    'viewBoat': '/boat/view_boat',
    'deleteBoat': '/boat/delete_boat',
    'getBoatBookingServerSideList': '/BoatBooking/Boat_Booking_Server_Side_list',
    'getBoatKanbanBoardList': '/BoatBooking/KanbanBoatBooking_list',
    'boateBookingChangeStatus': '/BoatBooking/update_kanbanStatus_BoatBooking',
    'boatBookingRemarksupdate': '/BoatBooking/update_kanbanRemarks_BoatBooking',
    'getBoatCalendarList': '/BoatBooking/BoatBooking_list',
    'addBoatBooking': '/BoatBooking/create_boat_booking',
    'editBoatBooking': '/BoatBooking/update_boat_booking',
    'deleteBoatBooking': '/BoatBooking/delete_BoatBooking',
    'viewBoatBooking': '/BoatBooking/view_BoatBooking',
    'DurationUnitList': '/boat_house_activity/ddl_duration_unit_list',
    'addBoatHouseActivity': '/boat_house_activity/create_boat_house_activity',
    'editBoatHouseActivity': '/boat_house_activity/update_boat_house_activity',
    'getBoatHouseActivityList': '/boat_house_activity/boat_house_activity_list',
    'viewBoatHouseActivity': '/boat_house_activity/view_boat_house_activity',
    'deleteBoatHouseActivity': '/boat_house_activity/delete_boat_house_activity',
    'cmsaddBoatHouseActivity': '/boat_house_activity/cms_create_boat_house_activity_language',
    'cmseditBoatHouseActivity': '/boat_house_activity/cms_edit_boat_house_activity_language',
    'cmsviewBoatHouseActivity': '/boat_house_activity/cms_view_boat_house_activity_language',
    'cmsBoatHouseActivityList': '/boat_house_activity/cms_boat_house_activity_language_list',
    'getSessionList': '/session/Session_list',
    'addSession': '/session/create_session',
    'editSession': '/session/update_session',
    'viewSession': '/session/view_session',
    'deleteSession': '/session/delete_session',
    'createAvtivitySession': '/session/create_session_with_startdate_enddate_multislots',
    'GetBoatLanguageList': '/boat/cms_boat_language_list',
    'ViewBoatLanguageList': '/boat/cms_view_boat_Content',
    'CreateBoatLanguageContent': '/boat/cms_create_boat_Content',
    'UpdateBoatLanguageContent': '/boat/cms_update_boat_Content',
    'GetPriceListForBoatactivityId':'/BoatBooking/get_boating_offer_list_by_boatactivityid',


    //Amenity
    'getAmenityList': '/Amenity/amenity_list',
    'addAmenity': '/Amenity/create_amenity',
    'editAmenity': '/Amenity/update_amenity',
    'viewAmenity': '/Amenity/view_amenity',
    'deleteAmenity': '/Amenity/delete_amenity',
    'getcmsAmenityLangList': '/Amenity/cms_amenity_language_list',
    'addcmsAmenity': '/Amenity/cms_create_amenity_language',
    'editcmsAmenity': '/Amenity/cms_edit_amenity_language',
    'viewcmsAmenity': '/Amenity/cms_view_amenity_language',
    'getAmenityBookingList': '/AmenityBooking/AmenityBooking_list',
    'addAmenityBooking': '/AmenityBooking/Create_Calender_AmenityBooking',
    'editAmenityBooking': '/AmenityBooking/Update_Calender_AmenityBooking',
    'viewAmenityBooking': '/AmenityBooking/View_AmenityBooking',
    'deleteAmenityBooking': '/AmenityBooking/Delete_Calender_AmenityBooking',
    'getAmenityKanbanList': '/AmenityBooking/KanbanAmenityBooking_list',
    'amenityBookingStatusChange': '/AmenityBooking/update_kanbanStatus_AmenityBooking',
    'amenityRamarksUpdate': '/AmenityBooking/update_kanbanRemarks_AmenityBooking',
    'GetAmenityPrice':'/AmenityBooking/Get_Price_By_AmenityId',

    //RoomService
    'getddlRoomServiceBookingApprovalStatuslist': '/room_service_booking/ddlRoomServiceBooking_ApprovalStatus_list',
    'addRoomServiceBooking': '/room_service_booking/create_RoomServiceBooking',
    'editRoomServiceBooking': '/room_service_booking/update_RoomServiceBooking',
    'viewRoomServiceBooking': '/room_service_booking/view_RoomServiceBooking',
    'getRoomServiceBookingList': '/room_service_booking/RoomServiceBooking_list',
    'deleteRoomServiceBooking': '/room_service_booking/delete_RoomServiceBooking',
    'GetRoomServiceList': '/RoomServices/room_services_list',
    'getRoomServiceKanbanBoardList': '/room_service_booking/KanbanRoomServiceBooking_list',
    'RoomServicestatusChange': '/room_service_booking/update_kanbanStatus_RoomServiceBooking',
    'RoomServiceRemarksUpdate': '/room_service_booking/update_kanbanRemarks_RoomServiceBooking',
    'AddServiceAvailability': '/service_type/add_service_type',
    'EditServiceAvailability': '/service_type/edit_service_type',

    //CheckInDetails
    'getcheckindetailsList': '/check_in_details/list_CheckInDetail',
    'addCheckInDetails': '/check_in_details/create_CheckInDetail',
    'editCheckInDetails': '/check_in_details/update_CheckInDetail',
    'deleteCheckInDetails': '/check_in_details/delete_CheckInDetail',
    'viewCheckInDetails': '/check_in_details/view_CheckInDetail',
    'getddlCountryList': '/check_in_details/ddlCountry_list',

    // ADMIN
    'getAdminDTList': '/user/user_list',
    'getAdminCategory': '/module/moduel_list',
    'getAdminRole': '/user/user_role_list',
    'createAdminUser': '/user/create_user',
    'getAdminUserById': '/user/view_user',
    'updateAdminUser': '/user/update_user',
    'deleteAdminUser': '/user/delete_user',

    //Customers
    'createCustomer': '/mobile_user/add_mobileuser',
    'updateCustomer': '/mobile_user/update_mobileuser',
    'deleteCustomer': '/mobile_user/delete_mobileuser',
    'ViewCustomer':'/mobile_user/view_MobileUserById',

    // Role
    'getRoleList': '/user_role/user_role_list',
    'createRole': '/user_role/create_user_role',
    'getPermission': '/user_role/permission_list',
    'viewUserRole': '/user_role/view_user_role_permission',
    'updateUserRole': '/user_role/update_user_role',
    'deleteUserRole': '/user_role/delete_user_role',

    //TherapistLeave
    'getTherapistLeaveList': '/therapist_leave/therapist_leave_list',
    'addTherapistLeave': '/therapist_leave/create_therapist_leave',
    'deleteTherapistLeave': '/therapist_leave/delete_therapist_leave',
    'editTherapistLeave': '/therapist_leave/update_therapist_leave',
    'ViewTherapistLeave': '/therapist_leave/view_therapist_leave',

    // PageContent
    'pageContentList': '/pagecontent_Api/page_content_list',


    'getPermissionsOfUser': '/customerlogin/user_permission_list',

    //Geo Location
    'getGeoLocationList': '/GeoLocation/geolocation_list',
    'addGeoLocation': '/GeoLocation/create_geolocation',
    'editGeoLocation': '/GeoLocation/update_geolocation',
    'viewGeoLocation': '/GeoLocation/view_geolocation',
    'deleteGeoLocation': '/GeoLocation/delete_geolocation',
    'getLanguageList': '/languages/language_list',
    'getTypeList': '/GeoLocation/ddlType_list',


    //Beacon Message
    'getBeaconMessageList': '/BeaconMessage/beacon_message_list',
    'addBeaconMessage': '/BeaconMessage/create_beacon_message',
    'editBeaconMessage': '/BeaconMessage/update_beacon_message',
    'viewBeaconMessage': '/BeaconMessage/view_beacon_message',
    'deleteBeaconMessage': '/BeaconMessage/delete_beacon_message',

    // User profile
    'forgotPassword': '/customerlogin/forget_password',
    'resetPassword': '/customerlogin/reset_password',


    //Phone directory
    'getPhoneDirectoryList': '/phonedirectory/cms_phonedirectory_language_list',
    'GetPhoneDirectoryLanuageContent': '/phonedirectory/cms_view_phonedirectory_language',
    'getCategoryList': '/phonedirectory/ddlPhoneDirectory_Category_list',
    'addPhoneDirectory': '/phonedirectory/cms_create_phonedirectory_language',
    'editPhoneDirectory': '/phonedirectory/cms_update_phonedirectory_language',
    'masterAddPhoneDirectory': '/phonedirectory/create_phonedirectory',
    'masterListPhoneDirectory': '/phonedirectory/phone_directory_list',
    'masterEditPhoneDirectory': '/phonedirectory/update_phonedirectory',
    'masterViewPhoneDirectory': '/phonedirectory/view_phonedirectory',
    'masterDeletePhoneDirectory':'/phonedirectory/delete_phonedirectory',


    //Service Tabbar
    'getServiceTabbarList': '/service_tabbar/cms_service_tabbar_language_list',
    'addServiceTabbar': '/service_tabbar/cms_create_service_tabbar_language',
    'editServiceTabbar': '/service_tabbar/cms_update_service_tabbar_language',
    'viewServiceTabbar': '/service_tabbar/cms_view_service_tabbar_language',

    //Place Of Interest
    'create_placeofinterest': '/placeofinterest/create_placeofinterest',
    'edit_placeofinterest': '/placeofinterest/edit_placeofinterest',
    'delete_deleteinterest': '/placeofinterest/delete_placeofinterest',

    'GetCMSPlaceOfInterestList': '/PlaceOfInterest/CMSListPlaceOfInterestLanguageMaster',
    'GetPlaceOfInterestLanguageContent': '/PlaceOfInterest/CMSViewPlaceOfInterestLanguageMaster',
    'CreatePlaceOfInterestLanguageContent': '/PlaceOfInterest/CMSCreatePlaceOfInterestLanguageMaster',
    'UpdatePlaceOfInterestLanguageContent': '/PlaceOfInterest/CMSEditPlaceOfInterestLanguageMaster',

    //SLA
    'view_managesla': '/ratingquestions/view_manage_sla',
    'updateManageSla': '/ratingquestions/update_manage_sla',
    'viewSlaEmail': '/email_template/view_template_list',
    'updateSlaEmailTemplate': '/email_template/update_email_template',
    'createSlaEmailTemplate': '/email_template/create_email_template',
    //Chart Report
    'dashboard_chart_report': '/dashboard/dashboard_chart_report',
    'getModuleFieldList': '/email_template/get_all_template_params',
    'getLoyaltyModuleFieldList':'/email_template/get_Loyalty_Programme_template_params',
    'getEventFieldList':'/email_template/get_notification_params',
    //Manage Cabin
    'getCabinList': '/cabin/cabin_list',
    'deleteCabin': '/cabin/delete_cabin',
    'viewCabin': '/cabin/view_cabin',
    'createCabin': '/cabin/create_cabin',
    'updateCabin': '/cabin/update_Cabin',
    'cabinAvailabilityCalendar': '/cabin/cabin_availability_calenderlist',
    'availableCabinList': '/cabin/available_cabin_list',

    //Cabin Language
    'GetCMSSpaCabinList': '/cabin/cms_cabin_language_list',
    'GetCMSSpaCabinContentView': '/cabin/cms_view_cabin_language',
    'CreateCMSSpaCabinContent': '/cabin/cms_create_cabin_language',
    'UpdateCMSSpaCabinContent': '/cabin/cms_edit_cabin_language',

    //Splash Content Name
    'getSplashContenthList': '/SplashContent/SplashContent_List',
    'splashContenthView': '/SplashContent/view_splash',
    'splashContenthCreate': '/SplashContent/create_Splash',
    'splashContenthUpdate': '/SplashContent/update_splash',
    'splashContenthDelete': '/SplashContent/delete_splash',


    //Splash Content Language
    'getSplashLangList': '/splashcontent/cms_splashcontent_language_list',
    'splashLangCreate': '/splashcontent/cms_create_splashcontent_language',
    'splashLangUpdate': '/splashcontent/cms_edit_splashcontent_language',
    'splashLangView': '/splashcontent/cms_view_splashcontent_language',

    //Twilio Chat 
    'getContactChatList': '/TwilioConversation/GetAllConversations',
    'getUsermsg': '/TwilioConversation/GetAllMsgsByConversationSid',
    'sendMessage': '/TwilioConversation/SendMessageByScreenageTeam',
    'checkChatmsg': '/TwilioConversation/IsNewMsgCame',

    'roomFeatureList': '/rooms_features/rooms_features_list',
    'updatePageContent': '/pagecontent_Api/v3update_page_content',
    'createPageContent': '/pagecontent_Api/v3create_page_content',

    // Food Menu 
    'getfoodmenulist': '/Food/GetFood_List',
    'createFood': '/Food/create_Food',
    'UpdateFood': '/Food/update_Food',
    'ViewFood': '/Food/view_Food_list',
    'DeleteFood': '/Food/delete_Food',
    'ddlRestaurant': '/Food/ddl_RestaurantName',
    'GetFoodLanguageList': '/Food/cms_Food_language_list',
    'ViewFoodLanguageList': '/Food/cms_view_Food_Content',
    'CreateFoodLanguageContent': '/Food/cms_create_Food_Content',
    'UpdateFoodLanguageContent': '/Food/cms_update_Food_Content',
    'FoodCategorylist': '/food_category/food_category_list',

    // Food Category
    'getfoodCategorylist': '/food_category/food_category_list',
    'createFoodCategory': '/food_category/create_food_category',
    'deleteFoodCategory': '/food_category/delete_food_category',
    'updateFoodCategory': '/food_category/update_food_category',
    'viewFoodCategory': '/food_category/view_food_category',
    'getfoodCategorylanguagelist': '/food_category/CMSList',
    'ViewFoodCategoryLanguageList': '/food_category/CMSView',
    'CreateFoodCategoryLanguage': '/food_category/CMSCreate',
    'UpdateFoodCategoryLanguage': '/food_category/CMSEdit',

    // Module Wise Range Setting
    'GetModuleList': '/module/moduel_list_for_CommonSetRanges',
    'ViewModulebasedsettings': '/module/view_module_by_ModuleId',
    'UpdateModulesetting': '/module/edit_module',
    'getModelwitheventlist':'/module/Modulelist_Notification',
    //Hoilday 
    'getHoildayList': '/holiday/All_holiday_list',
    'createHoilday': '/holiday/create_holiday',
    'updateHoilday': '/holiday/update_holiday',
    'ViewHoilday': '/holiday/view_holiday',
    'DeleteHoilday': '/holiday/delete_holiday',
    'getModulelistforhoilday': '/module/moduel_list_for_Hoilday',

    //DateRemoving
    'getdateremovinglist': '/DateRemovetiming/AllDateRemovingTiming_list',
    'createdateremove': '/DateRemovetiming/create_DatewiseRemovingTimings',
    'updatedateremove': '/DateRemovetiming/update_DateRemovetiming',
    'viewdateremove': '/DateRemovetiming/view_DateRemovetiming',
    'deletedateremove': '/DateRemovetiming/delete_DateRemovetiming',
    'ddlBookingTimimg': '/DateRemovetiming/ddlBookingTime_list',

    // BookingTime
    'getModulelistforBooking': '/module/moduel_list_for_BookingTiming',
    'getallbookingtimeList': '/Bookingtiming/BookingTimingWithModuleId_list',
    'createbookingtime': '/Bookingtiming/create_BookingTimings',
    'updatebookingtime': '/Bookingtiming/update_Bookingtiming',
    'viewbookingtime': '/Bookingtiming/view_Bookingtiming',
    'deletebookingtime': '/Bookingtiming/delete_Bookingtiming',

    //RestaurantBookingupdate
    'getFetchRestaurantBookingTimeInfo': '/Restaurant/FetchRestaurantBookingTimeInfo',
    'getRestaurantBookingTime': '/Booking_date_wise_timings/Booking_date_timings_list',
    'getFetchSpaBookingTimeInfo': '/spa_booking/FetchSpaBookingTimeInfo',
   // 'getspaBookingTime': '/spa_booking/spa_not_available_timings_list_by_Date',
    'getspaBookingTime': '/spa_booking/spa_available_timings_list_by_Date',
    'getFetchbikeBookingTimeInfo': '/bike_booking/FetchBikeBookingTimeInfo',
    'getbikeBookingTime': '/bike_booking/bike_not_available_timings_list_by_Date',
    'getscheduleormanualBoatBooking': '/BoatBooking/get_boating_date_list_by_boatactivityid',
    'getFetchBikeBooking': '/bike_booking/FetchBikeBookingTimeInfo',
   // 'getDatewiseTimelist': '/bike_booking/bike_not_available_timings_list_by_Date',
   'getDatewiseTimelist':'/bike_booking/bike_available_timings_list_by_Date',
    'getBookingTimelist': '/restaurant_timings/restaurant_available_timings_list_by_Date',

    //SpecialTiming
    'getModulelistforSpecialTiming': '/module/moduel_list_for_SpecialTiming',
    'getSpecialtimeList': '/special_booking_timings/special_booking_timings_list',
    'CreateSpecialtiming': '/special_booking_timings/CreateSpecialBookingTimings',
    'UpdateSpecialtiming': '/special_booking_timings/update_special_booking_timings',
    'ViewSpecialTiming': '/special_booking_timings/view_special_booking_timings',
    'deleteSpecialTiming': '/special_booking_timings/delete_special_booking_timings',
    //Other Service details
    'getOtherservicedetails': '/other_service_detail/other_service_detail_list',
    'CreateOtherServicedetail': '/other_service_detail/create_other_service_detail',
    'UpdateOtherServiceDetail': '/other_service_detail/update_other_service_detail',
    'deleteotherservicedetail': '/other_service_detail/delete_other_service_detail',
    'Viewotherservicedetails': '/other_service_detail/view_other_service_detail',

    //Other service Language
    'getotherservicelist': '/other_service_detail/CMSList',
    'createotherservicelanguage': '/other_service_detail/CMSCreate',
    'viewotherservicelanugage': '/other_service_detail/CMSView',
    'updateotherservicelanguage': '/other_service_detail/CMSEdit',

    //Tier 
    'gettierlist': '/tier/tier_list',
    'createtierlist': '/tier/create_tier',
    'viewtierlist': '/tier/view_tier',
    'Updatetierlist': '/tier/update_tier',
    'deletetierlist': '/tier/delete_tier',
    'getTierlevelNamelist':'/tier/tier_level_list',

    //tier language
    'gettiercmslist': '/tier/CMSList',
    'createtiercmslist': '/tier/CMSCreate',
    'viewtiercmslist': '/tier/CMSView',
    'updatecmstier': '/tier/CMSEdit',

    //Customer total point
    'getCustomertotalpointList': '/customer_points/customer_total_point_list',
    'UpdateUserTier':'/customer_points/UpdateUserTier',


    //points
    'getpointList': '/points_settings/points_settings_list',
    'createpoints': '/points_settings/create_point',
    'updatepoints': '/points_settings/update_Point',
    'viewpoint': '/points_settings/view_point',
    'deletepoint': '/points_settings/delete_point',

    //Booking Amount update		
    'bookingBillAmountupdate': '/customer_points/create_customer_points',
    //Customer Point		
    'getCustomerPointList': '/customer_points/customer_point_list',
    'updateCustomerRedeemPoints': '/customer_points/Update_customer_Redeem_points',

    //FAQ
    'getFAQList': '/FAQ/GetFAQ_List',
    'createFAQ': '/FAQ/create_FAQ',
    'updateFAQ': '/FAQ/update_FAQ',
    'viewFAQ': '/FAQ/view_FAQ',
    'deleteFAQ': '/FAQ/delete_FAQ',

    //FAQ Language
    'GetCMSFAQList': '/FAQ/cms_FAQ_language_list',
    'createCMSFAQ': '/FAQ/cms_create_FAQ_Content',
    'updateCMSFAQ': '/FAQ/cms_update_FAQ_Content',
    'viewCMSFAQ': '/FAQ/cms_view_FAQ_Content',

    //Claim Missing Points
    'getClaimMissingPoints': '/Claim_Missing_point/Claim_Missing_point_list',
    'deleteClaimMissingPoints': '/Claim_Missing_point/delete_Claim_Missing_point',
    'viewClaimMissingPoints': '/Claim_Missing_point/view_Claim_Missing_point',
    'updateClaimMissingPoints': '/Claim_Missing_point/Update_Claim_missing_points',

    //Transaction History
    'getCustomerPointHistory': '/customer_points/customer_point_history_by_customerId',
    'requestCustomerRedeemPoints': '/customer_points/insert_redeem_customer_points_request',
    'CreateCustomerPoints':'/customer_points/create_customer_points',
    'ViewCustomerpointsDetails':'/mobile_user/view_MobileUserById',
    //
   // 'GetRoomServiceList' : '/RoomServices/room_services_list',
    'CreateRoomService'  : '/RoomServices/create_room_service',
    'ViewRoomService'    : '/RoomServices/view_room_service',
    'EditRoomService'    : '/RoomServices/update_room_service',
    'DeleteRoomService'  : '/RoomServices/delete_room_service',

    'GetRSLanguageList'         : '/RoomServices/cms_room_services_list',
    'GetRSLanguageContent'      : '/RoomServices/view_item_language_master_by_room_serviceid_languageId',
    'CreateRSLanguageContent'   : '/RoomServices/create_item_language_master',
    'UpdateRSLanguageContent'   : '/RoomServices/edit_itemLanguage_master_by_roomserviceId_languageId',
    'UploadRoomserviceLogo'     :  '/SettingsGeneral_Api/UpdateRoomServiceImage',

    //Service_available
    'GetAvailabilityList' : '/service_available/service_availability_list',
    'ViewAvailablility' : '/service_available/view_service_available',
    'EditAvailability': '/service_available/edit_service_available',
    'getServiceTypeList':'/service_available/service_type_list',
    'deleteAvailability':'/service_type/delete_service_type',
    'GetServiceLanguageList': '/service_type/cms_Service_language_list',
    'ViewServiceLanguageList': '/service_type/cms_view_service_type',
    'CreateServiceLanguageContent': '/service_type/cms_create_service_type',
    'UpdateServiceLanguageContent': '/service_type/cms_update_service_type',

    //manage Notification
    'GetviewNotification':'/Notifications/NotificationContentList',
    'UpdateNotification':'/Notifications/UpdateNotificationContent',
    
    // Role Permission

   'viewRolepermissionByRoleId':'/access_rights/v3_permission_list_by_roleId',
   'viewRolepermissionByUserId':'/RolePermission/GetRolePermissionByUserId',
   'updateRolePermission':'/access_rights/UpdateRolePermissionListByRoleId',


    //Event Language
    'GetCMSEventList': '/event/cms_Event_language_list',
    'GetCMSEventContentView': '/event/cms_view_event_language',
    'CreateCMSEventContent': '/event/cms_create_event_language',
    'UpdateCMSEventContent': '/event/cms_edit_event_language',
}

export { URI };